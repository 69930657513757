import React, { useState } from 'react';

import { ProjectStatus, ProjectType } from '@/api/project';
import { OptionAllType, OptionMapType } from '@/components/ProjectManagements/Applicant/Contents/common/Types/ArgsType';
import Popup_OptionSetting from '@/components/ProjectManagements/Modals/Popup_OptionSetting';
import ProjectOverview from '@/components/ProjectManagements/Modals/Project_OptionOverview';

interface Props {
  projectType: ProjectType;
  projectStatus: ProjectStatus;
  option: OptionAllType;
  setOption: React.Dispatch<React.SetStateAction<OptionAllType>>;
  optionMap: OptionMapType;
  PutPostOption: (option: OptionAllType) => Promise<void>;
  isRequesting?: boolean;
}

export default function Project_Title(props: Props): React.ReactElement {
  const [optionModalIsOpen, setOptionModalIsOpen] = useState(false);
  return (
    <>
      {/*オプション設定*/}
      <div className="h-[326+48px] w-[678.75px] gap-[16px] pl-[24px] pt-[48px]">
        <div className="flex h-[28px] w-[678.75px] justify-between">
          <div className="h-[28px] w-[344px] content-center text-base font-semibold leading-7 text-gray-700">
            オプション設定
          </div>
          {(props.projectStatus === ProjectStatus.Draft && props.isRequesting) ||
          props.projectStatus === ProjectStatus.PostReview ||
          props.projectStatus === ProjectStatus.Review ||
          props.projectStatus === ProjectStatus.Posting ||
          props.projectStatus === ProjectStatus.PostCompletion ? (
            <></>
          ) : (
            <div className="h-[20px] content-center pt-[2px]">
              <button
                type="button"
                className="text-base font-medium leading-5 text-blue-500 shadow-sm"
                onClick={() => {
                  setOptionModalIsOpen(true);
                }}
              >
                編集
              </button>
            </div>
          )}
        </div>
        {/*Frame 10291 ここから*/}
        <div className="h-[266+24px] w-[678.75px] pt-[24px]">
          <ProjectOverview option={props.option} optionMap={props.optionMap} />
        </div>
      </div>
      <div>
        <Popup_OptionSetting
          open={optionModalIsOpen}
          setOpen={setOptionModalIsOpen}
          projectType={props.projectType}
          projectStatus={props.projectStatus}
          option={props.option}
          setOption={props.setOption}
          optionMap={props.optionMap}
          PutPostOption={props.PutPostOption}
        />
      </div>
    </>
  );
}
