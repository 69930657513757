import clsx from 'clsx';
import { useState, createContext, Dispatch, useEffect, useCallback } from 'react';

import { JaneJohnTask, JaneJohnTaskType, ProjectSummaryBase, GetJaneJohnDetailOutput } from '@/api/janeJohn';
import { ProjectStatus, ProjectType, SnsType } from '@/api/project';
import { ReactComponent as ErrorCircleIcon } from '@/assets/icons/bx-error-circle.svg';
import NoImage from '@/assets/images/NoImage.png';
import SmartPhone from '@/assets/images/SmartPhone.png';
import instagram from '@/assets/instagram.png';
import tiktok from '@/assets/tiktok.png';
import x from '@/assets/TwitterLogo.png';
import youtube from '@/assets/youtube.png';
import { Button } from '@/components/Elements';
import { Badge } from '@/components/Elements/Badge';
import Estimate from '@/components/ProjectManagements/Components/Estimate';
import KpiComponent from '@/components/ProjectManagements/Components/KPI';
import { DetailImages } from '@/components/ProjectManagements/Modals/DetailImages';
import { FinalAgreeModal } from '@/components/ProjectManagements/Modals/FinalAgreeModal';
import { ProjectOperationDetails } from '@/components/ProjectManagements/Modals/ProjectOperationDetails';
import { ProjectOverview, ProjectOverviewProps } from '@/components/ProjectManagements/Modals/ProjectOverview';
import {
  ProjectRecruitingState,
  ProjectRecruitingTitle,
} from '@/components/ProjectManagements/Modals/ProjectRecruitingTitle';
import { PublicAttention, DetailSettings } from '@/components/ProjectManagements/Modals/PublicAttention';
import { PROJECT_TYPES } from '@/config';
import {
  GetJaneJohnDetail,
  PutJaneJohnMedia,
  PutJaneJohnNotice,
  PutJaneJohnSummary,
  PutJaneJohnTask,
  PutJaneJohnTitle,
} from '@/hooks/JaneJohn';
import { insertPostApplicationInfo } from '@/hooks/project';
import { getImageUrl, getImageUrlIfRelative } from '@/utils/image';
import { ValidationMessages } from '@/utils/message';
// import { snsIcon } from '@/utils/sns';

import { projectDetail } from '../../interfaces/projectInterface';

import { MobilePreview } from './mobilePreview';

export const PopupDetail_JANEJOHN = createContext({
  buttonState: {},
  buttonDispatch: {} as Dispatch<any>,
});

export default function Recruitment(props: projectDetail) {
  const { projectId, detailImages, setDetailImages, setStatus } = props; // propsから必要な変数を展開

  const [finishAgreeModalIsOpen, setFinishAgreeModalIsOpen] = useState(false);
  const [isRequesting, setIsRequesting] = useState(props.status !== ProjectStatus.Draft);
  //const [radioBoxValue, setRadioBoxValue] = useState('1');
  const radioBoxValue = '1';
  //const [prefecturesRadioBoxValue, setPrefecturesRadioBoxValue] = useState('from_off');
  const prefecturesRadioBoxValue = 'from_off';
  //const [selectedPrefectures, setSelectedPrefectures] = useState<string[]>([]);
  const selectedPrefectures: string[] = [];
  const [agreeErrorMessage, setAgreeErrorMessage] = useState('');

  //const [detailMsg, setDetailMsg] = useState<string>('');
  const descriptionItem = (key: string, value: string | string[] | undefined) => {
    return (
      <>
        <div className="flex flex-row items-center whitespace-pre pt-[16px]">
          <div className="min-h-[20px] w-[233px]">
            <span className="text-sm font-semibold leading-5 text-gray-500">{key}</span>
          </div>
          <div className="flex min-h-[20px] w-[445.75px] flex-wrap gap-2">
            {typeof value == 'string' ? (
              <p className="whitespace-pre-line text-sm font-semibold leading-5 text-gray-900">{value}</p>
            ) : (
              value !== undefined &&
              value.map((item, index) => {
                return (
                  <div key={index} className="pt-[10px]">
                    <div
                      className={`${
                        key === '歓迎条件' && item != '-'
                          ? 'inline-flex items-center rounded-xl bg-gray-100 px-3 py-0.5 text-sm font-medium text-gray-800'
                          : 'whitespace-pre-line text-sm font-semibold leading-5 text-gray-900'
                      }`}
                    >
                      {key === '歓迎条件' ? item : `・${item}`}
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
        <div className="h-[1px] w-[678.75px] pt-[16px]">
          <div className="border-[1px] border-gray-200"></div>
        </div>
      </>
    );
  };
  const dateChange = (inputDate: string | null) => {
    console.log('props::::::::::::::::::' + props);
    if (inputDate === null) {
      return '-';
    } else {
      const date = new Date(inputDate);
      const year = String(date.getFullYear());
      const month = String(date.getMonth() + 1);
      const date2 = String(date.getDate());
      return `${year}/${month.padStart(2, '0')}/${date2.padStart(2, '0')}`;
    }
  };

  interface projectInterface {
    adoption?: string;
    reward?: string;
    description?: string;
    follower?: string;
    gender?: string;
    minAge?: string;
    maxAge?: string;
    location?: string;
    minIncome?: string;
    maxIncome?: string;
    job?: string;
    main?: string;
    sub?: string;
    genres?: string[];
  }

  const projectInterfaceArges: projectInterface = {
    adoption: props.recruitmentPlanCount.toString(),
    reward: props.rewardAmount.toString(),
    description: props.overviewDescription,
    genres: props.genres,
  };

  // 案件概要用変数修正版
  const ProjectOverviewArgs: ProjectOverviewProps = {
    open: false,
    recruitmentPlanCount: props.recruitmentPlanCount,
    displayRecruitmentPlanCount: '',
    rewardAmount: props.rewardAmount,
    displayRewardAmount: props.rewardAmount.toString(),
    tags: props.dialogTags,
    displayTags: [],
    tag: props.tag,
    description: props.overviewDescription,
    displayDescription: '',
    project: projectInterfaceArges,
    projectName: PROJECT_TYPES.JANE_JOHN,
    radioBoxValue: radioBoxValue,
    displaySelectedPrefectures: [],
    selectedPrefectures: selectedPrefectures,
    prefecturesRadioBoxValue: prefecturesRadioBoxValue,
  };

  const [projectOverviewProps, setProjectOverviewProps] = useState<ProjectOverviewProps>(ProjectOverviewArgs);
  //詳細画像用変数

  const [projectJaneJohnTask, setProjectJaneJohnTask] = useState<JaneJohnTask>(props.janeJohnDetailSetting.task);
  // const [projectJaneJohnDetailImages, setProjectJaneJohnDetailImages] = useState<ProjectImageRow[]>(
  //   props.janeJohnDetailSetting.detailImages
  // );

  const detailOperationTitle = (operationType: JaneJohnTaskType) => {
    console.log('-----------detailOperationTitle-------------');
    console.log(operationType);
    if (operationType == JaneJohnTaskType.SpotConsul) {
      return 'スポットコンサル';
    } else if (operationType == JaneJohnTaskType.Questionnaire) {
      return 'アンケート';
    } else {
      return 'グループインタビュー';
    }
  };

  // const selectedEstimate = (): EstimateProps[] => {
  //   if (props.janeJohnTask.task.type == JaneJohnTaskType.SpotConsul) {
  //     return [
  //       {
  //         title: 'インフルエンサー報酬費',
  //         quantity: props.status == 1 ? undefined : 2,
  //         unitPrice: props.status == 1 ? undefined : 15000,
  //       },
  //       {
  //         title: 'ディレクション費',
  //         quantity: props.status == 1 ? undefined : 2,
  //         unitPrice: props.status == 1 ? undefined : 43000,
  //       },
  //       {
  //         title: 'プラットフォーム利用費',
  //         quantity: props.status == 1 ? undefined : 1,
  //         unitPrice: props.status == 1 ? undefined : 76580,
  //       },
  //     ];
  //   } else if (props.janeJohnTask.task.type == JaneJohnTaskType.Questionnaire) {
  //     return [
  //       {
  //         title: 'インフルエンサー報酬費',
  //         quantity: props.status == 1 ? undefined : 2,
  //         unitPrice: props.status == 1 ? undefined : 15000,
  //       },
  //       {
  //         title: 'ディレクション費',
  //         quantity: props.status == 1 ? undefined : 2,
  //         unitPrice: props.status == 1 ? undefined : 43000,
  //       },
  //       {
  //         title: 'プラットフォーム利用費',
  //         quantity: props.status == 1 ? undefined : 1,
  //         unitPrice: props.status == 1 ? undefined : 76580,
  //       },
  //     ];
  //   } else {
  //     return [
  //       {
  //         title: 'グループインタビュー運営費用',
  //         quantity: props.status == 1 ? undefined : 2,
  //         unitPrice: props.status == 1 ? undefined : 15000,
  //       },
  //       {
  //         title: 'オプション費用',
  //         quantity: props.status == 1 ? undefined : 1,
  //         unitPrice: props.status == 1 ? undefined : 18000,
  //       },
  //     ];
  //   }
  // };

  const detailOperationContent = (task: JaneJohnTask) => {
    const questionCountMap = {
      to_10: '〜10問',
      to_20: '〜20問',
      to_30: '〜30問',
      to_40: '〜40問',
      to_50: '〜50問',
      to_60: '〜60問',
    };
    const questionCreateMap = {
      own: '自社で作成する',
      other: '作成を依頼する',
    };
    if (task.type == JaneJohnTaskType.SpotConsul) {
      return (
        <>
          {descriptionItem(
            '実施期間',
            task.spotConsul?.startedAt !== undefined && task.spotConsul?.endedAt !== undefined
              ? `${dateChange(task.spotConsul.startedAt)}〜${dateChange(task.spotConsul.endedAt)}`
              : '-'
          )}
        </>
      );
    } else if (task.type == JaneJohnTaskType.Questionnaire) {
      return (
        <>
          {descriptionItem(
            'アンケート実施日',
            task.question?.interviewDate !== undefined ? `${dateChange(task.question.interviewDate)}` : '-'
          )}
          {descriptionItem(
            'アンケート設問数',
            task.question?.questionNum !== undefined ? questionCountMap[task.question.questionNum] : '-'
          )}
          {descriptionItem(
            'アンケート作成',
            task.question?.creationType !== undefined ? questionCreateMap[task.question.creationType] : '-'
          )}
        </>
      );
    } else {
      return (
        <>
          {descriptionItem(
            'インタビュー実施日',
            task.groupInterView?.interviewDate !== undefined ? `${dateChange(task.groupInterView.interviewDate)}` : '-'
          )}
        </>
      );
    }
  };

  const checkParams = (): boolean => {
    if (
      // 案件募集タイトル
      projectTitleState.displayTitle.length > 0 &&
      projectTitleState.displayTags &&
      projectTitleState.displayTags.length > 0 &&
      projectTitleState.displayImage &&
      projectTitleState.displayImage.length > 0 &&
      // 詳細画像
      props.detailImages.length > 0 &&
      // 案件概要
      projectOverviewProps.displayRecruitmentPlanCount.length > 0 &&
      projectOverviewProps.displayRewardAmount.length > 0 &&
      //projectOverviewProps.displayTags.length > 0 &&
      projectOverviewProps.displayDescription.length > 0 &&
      // 稼働内容詳細
      projectJaneJohnTask &&
      ((projectJaneJohnTask.type === JaneJohnTaskType.GroupInterview &&
        projectJaneJohnTask.groupInterView?.interviewDate) ||
        (projectJaneJohnTask.type === JaneJohnTaskType.SpotConsul &&
          projectJaneJohnTask.spotConsul?.startedAt &&
          projectJaneJohnTask.spotConsul.endedAt) ||
        (projectJaneJohnTask.type === JaneJohnTaskType.Questionnaire &&
          projectJaneJohnTask.question?.interviewDate &&
          projectJaneJohnTask.question.questionNum &&
          projectJaneJohnTask.question.creationType))
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleAgreeClick = useCallback(async () => {
    const response = await props.janeJohnSummary;
    const minimumReward = response.estimate.calculateReward.minimum ?? 5000;
    const inputReward = response.summary.rewards ?? 0;
    const task = response.task ?? '';
    if (inputReward < minimumReward) {
      setAgreeErrorMessage(ValidationMessages.moreThanTargetMessage('インフルエンサー報酬額', minimumReward));
      return;
    }
    if (!task.type) {
      setAgreeErrorMessage('実施案件対応を指定してください');
      return;
    }
    setAgreeErrorMessage('');
    setFinishAgreeModalIsOpen(true);
  }, [props.rewardAmount]);

  const finalAgree = async (): Promise<void> => {
    // TODO: ここでAPIを叩く
    await insertPostApplicationInfo(projectId);
    setFinishAgreeModalIsOpen(false);
    setIsRequesting(true);
    props.setIsRequesting(true);

    const projectDetailOutput: GetJaneJohnDetailOutput = await GetJaneJohnDetail(projectId);
    setStatus(projectDetailOutput.header.projectStatus);
  };

  const detailSettingArgs: DetailSettings = {
    open: false,
    projectType: ProjectType.JaneJohn,
    companyName: props.janeJohnDetailSetting.details.companyName,
    isSecret: props.janeJohnDetailSetting.details.isSecret,
    notice: props.janeJohnDetailSetting.details.notice ? props.janeJohnDetailSetting.details.notice : [],
  };

  const [detailSettingProps, setDetailSettingProps] = useState<DetailSettings>(detailSettingArgs);

  const initRecruitingTitle = (sns: string, title?: string, tags?: string[], image?: string) => {
    setProjectTitleState((prev) => ({
      ...prev,
      title: title ? title : '',
      displayTitle: title ? title : '',
      tags: tags ? tags : [],
      displayTags: tags ? tags : [],
      displayImage: image ? getImageUrl(image) : NoImage,
      sns: sns,
    }));
  };

  const projectRecruitingTitleProps: ProjectRecruitingState = {
    open: false,
    title: '',
    sns: props.sns,
    file: '',
    displayTitle: props.projectTitle ? props.projectTitle : '',
    tags: [],
    tag: '',
    displayTags: props.tags ?? [],
    displayImage: props.projectImage ? getImageUrl(props.projectImage) : NoImage,
    projectName: ProjectType.JaneJohn,
    titleError: false,
    tagError: false,
    imageError: false,
    validationTitleMessage: '',
    validationTagMessage: '',
    validationImageMessage: '',
  };

  const [operationDetailsModalOpen, setOperationDetailsModalOpen] = useState<boolean>(false);

  const [projectTitleState, setProjectTitleState] = useState<ProjectRecruitingState>(projectRecruitingTitleProps);
  const [imageModalIsOpen, setImageModalIsOpen] = useState<boolean>(false);
  // const [projectOperationDetailsIsOpen, setProjectOperationDetailsIsOpen] = useState<boolean>(false);
  const PutJaneJohnTitleUpdate = (projectId: string) => async (title: string, tags: string[], featuredImage?: File) => {
    const response = await PutJaneJohnTitle(projectId, title, tags, featuredImage);
    props.setJaneJohnTitle(response);
  };
  const PutJaneJohnMediaUpdate = (projectId: string) => async (uploads?: File[], deleteIds?: string[]) => {
    const response = await PutJaneJohnMedia(projectId, uploads, deleteIds);
    console.log('PutJaneJohnMedia response:', response);
    if (response && response.detailImages) {
      console.log('if (response && response.detailImages) :', response.detailImages);
      if (response && response.detailImages) {
        const updatedImages = response.detailImages.map((image) => ({
          objectKey: getImageUrl(image.objectKey),
          id: image.id,
        }));
        setDetailImages(updatedImages);
      }
    }
  };

  const [detailImagesProjectKeys, setDetailImagesProjectKeys] = useState<string[]>([]);

  const PutJaneJohnProjectNotice = (projectId: string) => async (isPublic: boolean, notice: string[]) => {
    const response = await PutJaneJohnNotice(projectId, isPublic, notice);
    props.setJaneJohnNotice(response);
  };

  const PutJaneJohnProjectTask = (projectId: string) => async (janeJohnTask?: JaneJohnTask) => {
    const response = await PutJaneJohnTask(projectId, janeJohnTask);
    props.setJaneJohnTask(response);
  };

  const PutJaneJohnProjectOverview = (projectId: string) => async (projectSummaryBase?: ProjectSummaryBase) => {
    const response = await PutJaneJohnSummary(projectId, projectSummaryBase);
    props.setJaneJohnSummary(response);
  };

  // ProjectTitleの値が変更されたら、stateを更新する
  useEffect(() => {
    initRecruitingTitle(props.sns, props.projectTitle, props.tags, props.projectImage);
  }, [props.projectImage, props.projectTitle, props.sns, props.tags]);

  useEffect(() => {
    console.log('Received detailImages:', detailImages);
    if (detailImages) {
      const detailImageUrls = detailImages.map((image) => getImageUrlIfRelative(image.objectKey));
      setDetailImagesProjectKeys(detailImageUrls);
    }
  }, [detailImages]);

  useEffect(() => {
    setProjectOverviewProps((prevState) => ({
      ...prevState,
      recruitmentPlanCount: props.recruitmentPlanCount,
      displayRecruitmentPlanCount: props.recruitmentPlanCount.toString(),
      rewardAmount: props.rewardAmount,
      displayRewardAmount: props.rewardAmount.toString(),
      tags: props.genres,
      displayTags: props.genres,
      description: props.overviewDescription,
      displayDescription: props.overviewDescription,
      project: projectInterfaceArges,
    }));
  }, [props.rewardAmount]);

  useEffect(() => {
    if (
      props.janeJohnDetailSetting.task.groupInterView?.interviewDate ||
      props.janeJohnDetailSetting.task.spotConsul?.startedAt ||
      props.janeJohnDetailSetting.task.question?.interviewDate
    ) {
      setProjectJaneJohnTask(props.janeJohnDetailSetting.task);
    }
    console.log('projectJaneJohnTask:', projectJaneJohnTask);
    console.log('props.janeJohnDetailSetting.task:', props.janeJohnDetailSetting.task);
  }, [props.janeJohnDetailSetting]);

  useEffect(() => {
    if (props.janeJohnNotice.details.companyName !== '') {
      setDetailSettingProps((prevState) => ({
        ...prevState,
        companyName: props.janeJohnNotice.details.companyName,
        isSecret: props.janeJohnNotice.details.isSecret,
        notice: props.janeJohnNotice.details.notice ? props.janeJohnNotice.details.notice : [],
      }));
    }
  }, [props.janeJohnNotice]);

  useEffect(() => {
    props.setStatus(props.status);
    setIsRequesting(props.status !== ProjectStatus.Draft);
  }, [props]);

  const doNothingAsync = async () => {};

  return (
    <>
      <div className="w-[1181px]">
        <div className="flex justify-between">
          <div className="pl-16">
            <span className="pt-[9px] text-lg font-medium leading-6 text-gray-900">募集内容詳細</span>
          </div>
          <div className="pr-16">
            {/* TODO バックエンド作成時はすべて、コンストに移行 */}
            {props.status === ProjectStatus.Draft && !isRequesting && (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  variant="primary"
                  size="sm"
                  style={{ margin: '10px' }}
                  disabled={checkParams()}
                  onClick={handleAgreeClick}
                >
                  この内容で申請する
                </Button>
                {!!agreeErrorMessage && <p className="text-base text-red-400">{agreeErrorMessage}</p>}
              </div>
            )}
            {props.status == ProjectStatus.Review && isRequesting && (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  variant="primary"
                  size="sm"
                  style={{ margin: '10px' }}
                  disabled={true}
                  onClick={() => {
                    setFinishAgreeModalIsOpen(true);
                  }}
                >
                  申請中
                </Button>
              </div>
            )}
          </div>
        </div>
        {!isRequesting && !checkParams() ? (
          <div className="mb-[10px] ml-[40px] mt-[24px] flex h-[80px] w-[1053px] bg-blue-50 p-[16px]">
            <div className="pl-4 pr-3 pt-4">
              <ErrorCircleIcon width={20} height={20} fill="#60A5FA"></ErrorCircleIcon>
            </div>
            <div className="h-[48px] w-[989px] text-blue-800">
              <p className="mb-[8px] h-[20px] w-[989px] text-sm font-medium leading-5">
                募集内容を確認し、申請をしてください。
              </p>
              <p className="h-[20px] w-[989px] text-sm font-medium leading-5">
                申請されましたら、LIDDELL担当者が再確認の上、募集に入ります。
              </p>
            </div>
          </div>
        ) : props.status == ProjectStatus.Review && isRequesting ? (
          <div className="mb-[10px] ml-[40px] mt-[24px] flex h-[80px] w-[1053px] bg-blue-50 p-[16px]">
            <div className="pl-4 pr-3 pt-4">
              <ErrorCircleIcon width={20} height={20} fill="#60A5FA"></ErrorCircleIcon>
            </div>
            <div className="h-[48px] w-[989px] text-blue-800">
              <p className="mb-[8px] h-[20px] w-[989px] text-sm font-medium leading-5">申請を受付けました。</p>
              <p className="h-[20px] w-[989px] text-sm font-medium leading-5">
                LIDDELL担当者が確認しておりますので、お待ちください。
              </p>
            </div>
          </div>
        ) : (
          ''
        )}
        <div className="flex flex-row">
          <div>
            <div className="flex pl-[40px] pt-[24px]">
              <div className="h-auto w-[726.5px] bg-white pb-[16px]">
                <div className="h-[44+48px] w-[695px] pl-[24px] pt-[24px]">
                  <div className="flex h-[44+16px] w-[678.75px] justify-between">
                    <div className="h-[28px] w-[344px] content-center">
                      <span className="text-lg font-semibold leading-7 text-gray-700">案件募集タイトル</span>
                    </div>
                    {props.status === ProjectStatus.Draft && !isRequesting ? (
                      <div>
                        <Button
                          variant="text"
                          size="sm"
                          onClick={() => {
                            console.log('props:' + JSON.stringify(props, null, 2));
                            setProjectTitleState((prev) => {
                              return { ...prev, open: true };
                            });
                          }}
                          style={{ padding: '0px' }}
                        >
                          編集
                        </Button>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className="flex flex-row pl-4 pt-6">
                  <div>
                    <img className="h-[116px] w-[174.12px]" src={projectTitleState.displayImage ?? NoImage} alt="" />
                  </div>
                  <div className="flex h-[116+24px] w-[695px] pl-[16px]">
                    <div className="h-[28px] w-[504.88px] content-center pl-[16px]">
                      <div className="text-base font-semibold leading-7">{projectTitleState.displayTitle}</div>
                      <div className="flex gap-x-[10px] pt-[13px]">
                        {projectTitleState.sns == SnsType.Instagram ? (
                          <div>
                            <img src={instagram} alt="instagram" className="h-[22.34px] w-[22.34px]" />
                          </div>
                        ) : projectTitleState.sns == SnsType.X ? (
                          <div>
                            <img src={x} alt="twitter" className="h-[22.34px] w-[22.34px]" />
                          </div>
                        ) : projectTitleState.sns == SnsType.Youtube ? (
                          <div>
                            <img src={youtube} alt="youtube" className="h-[22.34px] w-[22.34px]" />
                          </div>
                        ) : projectTitleState.sns == SnsType.TikTok ? (
                          <div>
                            <img src={tiktok} alt="tiktok" className="h-[22.34px] w-[22.34px]" />
                          </div>
                        ) : (
                          <></>
                        )}
                        <div className="flex gap-x-[10px]">
                          {projectTitleState.displayTags &&
                            projectTitleState.displayTags.map((tag, index) => {
                              return (
                                <div key={index} className="pb-2">
                                  <Badge color="gray" round="xl" size="xs" className="w-fit px-2 py-3">
                                    {tag}
                                  </Badge>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="h-[176+48px] w-[695px] pl-[24px] pt-[48px]">
                  <div className="flex h-[28px] w-[678.75px] justify-between">
                    <div className="h-[28px] w-[344px] content-center">
                      <span className="text-lg font-semibold leading-7 text-gray-700">詳細画像</span>
                    </div>
                    {props.status === ProjectStatus.Draft && !isRequesting ? (
                      <div>
                        <Button
                          variant="text"
                          size="sm"
                          onClick={() => setImageModalIsOpen(true)}
                          style={{ padding: '0px' }}
                        >
                          編集
                        </Button>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="flex h-[116+24px] w-[678.75px] gap-[24px] pt-[24px]">
                    <img
                      className="h-[116px] w-[174.12px]"
                      src={detailImagesProjectKeys.length > 0 ? detailImagesProjectKeys[0] : NoImage}
                      alt=""
                    />
                    <img
                      className="h-[116px] w-[174.12px]"
                      src={detailImagesProjectKeys.length > 1 ? detailImagesProjectKeys[1] : NoImage}
                      alt=""
                    />
                    <img
                      className="h-[116px] w-[174.12px]"
                      src={detailImagesProjectKeys.length > 2 ? detailImagesProjectKeys[2] : NoImage}
                      alt=""
                    />
                  </div>
                </div>
                <div className="h-[326+40px] w-[678.75px] gap-[16px] pl-[24px] pt-[40px]">
                  <div className="flex h-[28px] w-[678.75px] justify-between">
                    <div className="h-[28px] w-[344px] content-center">
                      <span className="text-lg font-semibold leading-7 text-gray-700">案件概要</span>
                    </div>
                    {props.status === ProjectStatus.Draft && !isRequesting ? (
                      <div>
                        <Button
                          variant="text"
                          size="sm"
                          //onClick={() => props.setOpenDialogProjectOverview(true)}
                          onClick={() => setProjectOverviewProps({ ...projectOverviewProps, open: true })}
                          style={{ padding: '0px' }}
                        >
                          編集
                        </Button>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="h-[1px] w-[678.75px] pt-[16px]">
                    <div className="border-[1px] border-gray-200"></div>
                  </div>
                  {descriptionItem(
                    '採用予定人数',
                    projectOverviewProps.displayRecruitmentPlanCount == '' ||
                      Number(projectOverviewProps.displayRecruitmentPlanCount) <= -1
                      ? '-'
                      : `${projectOverviewProps.displayRecruitmentPlanCount}人`
                  )}
                  {descriptionItem(
                    'インフルエンサーへの報酬',
                    projectOverviewProps.displayRewardAmount == '' ||
                      Number(projectOverviewProps.displayRewardAmount) <= -1
                      ? '-'
                      : `¥${Number(projectOverviewProps.displayRewardAmount).toLocaleString()}/月`
                  )}
                  {descriptionItem(
                    '歓迎条件',
                    // TODO : ここはタグの形式に変換が必要
                    projectOverviewProps.displayTags.length == 0 ? '-' : projectOverviewProps.displayTags
                  )}

                  {descriptionItem(
                    '案件説明',
                    projectOverviewProps.displayDescription ? projectOverviewProps.displayDescription : '-'
                  )}
                </div>
                <div className="h-[326+40px] w-[678.75px] gap-[16px] pl-[24px] pt-[40px]">
                  <div className="flex h-[28px] w-[678.75px] justify-between">
                    <div className="h-[28px] w-[344px] content-center">
                      <span className="text-lg font-semibold leading-7 text-gray-700">稼働内容詳細</span>
                    </div>
                    {props.status === ProjectStatus.Draft && !isRequesting ? (
                      <div>
                        <Button
                          variant="text"
                          size="sm"
                          onClick={() => {
                            setOperationDetailsModalOpen(true);
                          }}
                          style={{ padding: '0px' }}
                        >
                          編集
                        </Button>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="h-[28px] w-[344px] content-center">
                    <span className="text-sm font-semibold leading-5 text-gray-400">
                      {detailOperationTitle(projectJaneJohnTask.type)}
                      {/* {detailOperationTitle(props.janeJohnDetailSetting.task.type)} */}
                    </span>
                  </div>
                  <div className="h-[1px] w-[678.75px] pt-[16px]">
                    <div className="border-[1px] border-gray-200"></div>
                  </div>
                  {detailOperationContent(projectJaneJohnTask)}
                  {/* {detailOperationContent(props.janeJohnDetailSetting.task)} */}
                </div>
                <div className="h-[326+40px] w-[678.75px] gap-[16px] pl-[24px] pt-[40px]">
                  <div className="flex h-[28px] w-[678.75px] justify-between">
                    <div className="h-[28px] w-[344px] content-center">
                      <span className="text-lg font-semibold leading-7 text-gray-700">詳細設定</span>
                    </div>
                    {props.status === ProjectStatus.Draft && isRequesting === false ? (
                      <div>
                        <Button
                          variant="text"
                          size="sm"
                          onClick={() => {
                            console.log('------------------------detailSettingProps---------------------------');
                            console.log(detailSettingProps);
                            setDetailSettingProps({ ...detailSettingProps, open: true });
                          }}
                          style={{ padding: '0px' }}
                        >
                          編集
                        </Button>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="h-[1px] w-[678.75px] pt-[16px]">
                    <div className="border-[1px] border-gray-200"></div>
                  </div>
                  {descriptionItem('注意事項', detailSettingProps.notice.length > 0 ? detailSettingProps.notice : '-')}
                  {descriptionItem(
                    '企業名公開',
                    detailSettingProps.isSecret ? '募集時から全体に公開' : '採用者のみ公開'
                  )}
                </div>
                <div className="h-[326+40px] w-[678.75px] gap-[16px] pl-[24px] pt-[40px]">
                  <div className="flex h-[28px] w-[678.75px] justify-between">
                    <div className="h-[28px] w-[344px] content-center">
                      <span className="text-lg font-semibold leading-7 text-gray-700">スケジュール</span>
                    </div>
                  </div>
                  <div className="h-[1px] w-[678.75px] pt-[16px]">
                    <div className="border-[1px] border-gray-200"></div>
                  </div>
                  {props.scadule ? (
                    props.scadule
                      .filter((schedule) => !schedule.label.includes('フィードバック期間'))
                      .map((schedule) => {
                        return descriptionItem(
                          schedule.label,
                          schedule.from && schedule.to ? `${dateChange(schedule.from)}~${dateChange(schedule.to)}` : '-'
                        );
                      })
                  ) : (
                    <></>
                  )}
                </div>
                {
                  <>
                    <div className="flex flex-row items-center whitespace-pre pl-[24px] pt-[16px]">
                      <div className="flex min-h-[20px] w-[223px] flex-col">
                        <span className="pb-[16px] text-sm font-semibold leading-5 text-gray-500">
                          フィードバック期間
                        </span>
                        <div className="flex flex-col pl-[10px]">
                          <div className="text-sm font-medium leading-5 text-gray-700">インフルエンサー</div>
                          <div className="pt-[8px] text-sm font-medium leading-5 text-gray-700">企業</div>
                        </div>
                      </div>
                      <div className="min-h-[20px] w-[445.75px] gap-2 pt-[36px]">
                        {props.scadule ? (
                          props.scadule
                            .filter((schedule) => schedule.label.includes('フィードバック期間'))
                            .map((schedule, index) => {
                              return (
                                <p
                                  className={clsx(
                                    'whitespace-pre-line break-words text-sm font-semibold leading-5 text-gray-900',
                                    index !== 0 && 'pt-[8px]'
                                  )}
                                  key={schedule.label}
                                >
                                  {schedule.from && schedule.to
                                    ? `${dateChange(schedule.from)}〜${dateChange(schedule.to)}`
                                    : '-'}
                                </p>
                              );
                            })
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className="h-[1px] w-[678.75px] pt-[16px]">
                      <div className="border-[1px] border-gray-200"></div>
                    </div>
                  </>
                }
                {KpiComponent(props.kpi.items)}
                {Estimate(undefined)}
              </div>
            </div>
          </div>
          <div className="h-[689.76px] w-[286.25px]">
            <div className="h-[28+24px] w-[240+40px] pl-[40px] pt-[24px] text-base font-semibold leading-7 text-gray-500">
              インフルエンサー画面プレビュー
            </div>
            {props.status === ProjectStatus.Draft ? (
              <div>
                <img className="h-[597.76px] w-[326.25px] pl-[40px] pt-[16px]" src={SmartPhone} alt="" />
              </div>
            ) : (
              MobilePreview()
            )}
          </div>
        </div>
      </div>

      <ProjectRecruitingTitle
        projectRecruitngState={projectTitleState}
        setProjectRecruitingState={setProjectTitleState}
        PutTitle={PutJaneJohnTitleUpdate(projectId)}
      ></ProjectRecruitingTitle>
      <DetailImages
        projectType={ProjectType.JaneJohn}
        isOpen={imageModalIsOpen}
        setIsOpen={setImageModalIsOpen}
        detailImages={detailImages}
        PutPostMedia={PutJaneJohnMediaUpdate(projectId)}
      ></DetailImages>
      <ProjectOverview
        projectOverviewProps={projectOverviewProps}
        setProjectOverviewProps={setProjectOverviewProps}
        PutProjectOverview={doNothingAsync}
        PutProjectOverviewJaneJohn={PutJaneJohnProjectOverview(projectId)}
      ></ProjectOverview>
      <ProjectOperationDetails
        // isModalOpen={projectOperationDetailsIsOpen}
        operationDetailsModalOpen={operationDetailsModalOpen}
        setOperationDetailsModalOpen={setOperationDetailsModalOpen}
        operationDetailsProps={projectJaneJohnTask}
        setOperationDetailsProps={setProjectJaneJohnTask}
        PutProjectTask={PutJaneJohnProjectTask(projectId)}
      ></ProjectOperationDetails>
      <PublicAttention
        detailSettings={detailSettingProps}
        setDetailSettings={setDetailSettingProps}
        PutProjectNotice={PutJaneJohnProjectNotice(projectId)}
      />
      <FinalAgreeModal
        open={finishAgreeModalIsOpen}
        onClose={() => setFinishAgreeModalIsOpen(false)}
        onClickAgree={() => finalAgree()}
      />
    </>
  );
}
