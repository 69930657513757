import clsx from 'clsx';
import { useState } from 'react';

import { Tooltip } from '@/components/Elements/Tooltip/Tooltip';

export interface KPIItemProps {
  name: string;
  comment: string;
  descriptionList?: string[];
  isFontBold?: boolean;
}

export const KPIItem = (props: KPIItemProps) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  return (
    <>
      <div className="h-[1px] w-[678.75px] pt-[16px]">
        <div className="border-[1px] border-gray-200"></div>
      </div>
      <div className="flex h-full flex-row items-center whitespace-pre pt-[16px]">
        <div className="flex h-full w-[233px] flex-row items-center">
          <span
            className="text-sm font-semibold leading-5 text-gray-500"
            onMouseEnter={() => {
              if (props.descriptionList?.length) {
                setIsVisible(true);
              }
            }}
            onMouseLeave={() => {
              setIsVisible(false);
            }}
          >
            {props.name}
          </span>
          {isVisible && props.descriptionList && (
            <div className="group relative pl-1">
              <Tooltip
                descriptionList={props.descriptionList}
                isSpeechBubble={true}
                variant="gray"
                className="absolute left-[-40px] top-[-56px] group-hover:inline-block"
                toolTipClassName="px-3 py-2"
              ></Tooltip>
            </div>
          )}
        </div>
        <div className="min-h-[20px] w-[445.75px]">
          <p
            className={clsx(
              'whitespace-pre-line text-sm leading-5 text-gray-900',
              !props.isFontBold && 'font-semibold'
            )}
          >
            {props.comment}
          </p>
        </div>
      </div>
    </>
  );
};
