// import React from 'react';

import * as Sentry from '@sentry/react';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { GetTeamsOutput, InviteTeam, Team } from '@/api/team';
import { ReactComponent as BarChart } from '@/assets/icons/bx-bar-chart-alt.svg';
import { ReactComponent as Bell } from '@/assets/icons/bx-bell.svg';
import { ReactComponent as Calendar } from '@/assets/icons/bx-calendar.svg';
import { ReactComponent as Cog } from '@/assets/icons/bx-cog.svg';
import { ReactComponent as HomeIcon } from '@/assets/icons/bx-home.svg';
import { Tooltip } from '@/components/Elements/Tooltip/Tooltip';
import { ROUTES } from '@/config/path';
import { firstSidebarSelect } from '@/config/sidebar';
import { fetchAccountInfo } from '@/hooks/AccountInfo';
import { fetchTeamInfo, fetchTeamsInfo, teamCheckout } from '@/hooks/TeamInfo';
import { getImageUrl } from '@/utils/image';

import { DropDown, DropDownProps } from '../DropDown';

import helpCircleImage from './assets/helpCircle.png';

// storybookを動かすためにPropsを追加したが、内容とは無関係
export type FirstSidebarProps = React.HTMLAttributes<HTMLElement> & {
  actionText?: string;
  selected: string;
  dropDownProps?: DropDownProps;
};

export function FirstSidebar(props: FirstSidebarProps) {
  const [thumbnailUrl, setThumbnailUrl] = useState<string>(localStorage.getItem('teamIconUrl') || '');
  const [selectedTeamInfo, setSelectedTeamInfo] = useState<Team>({
    memberCount: 0,
    teamId: '',
    teamName: '',
    thumbnail: '',
  });
  const [teamsInfo, setTeamsInfo] = useState<GetTeamsOutput>({
    invitations: [] as InviteTeam[],
    joinings: [] as Team[],
  });

  const [unread, setUnread] = useState<number>(Number(localStorage.getItem('unreadCount')) || 0);

  //アカウント情報から通知/お知らせの未読数を取得
  const getAccountInfo = async () => {
    try {
      const accountInfo = await fetchAccountInfo();
      const unreadCount = accountInfo.unreadCount;
      // 通知数が一瞬消える対策としてローカルストレージに保存
      localStorage.setItem('unreadCount', unreadCount ? unreadCount.toString() : '');
      setUnread(unreadCount);
      console.log('accountInfo', accountInfo);
      console.log('unread', unread);
    } catch (error) {
      Sentry.captureException(error);
      // console.log(error);
    }
  };

  const getTeamInfo = async () => {
    const currentURL = window.location.href;
    console.log(currentURL);
    const url = new URL(currentURL);
    const searchParams = new URLSearchParams(url.search);
    const teamId = searchParams.get('teamId');
    console.log(teamId);
    try {
      if (teamId) {
        const teamCheckoutResult = await teamCheckout({ teamId });
        console.log('チーム切り替え', teamCheckoutResult);
      }
      const accountInfo = await fetchAccountInfo();
      const data = await fetchTeamInfo(accountInfo.teamId || '');
      console.log(data);
      setSelectedTeamInfo({
        ...selectedTeamInfo,
        memberCount: data.memberCount,
        teamId: data.teamId,
        teamName: data.teamName,
        thumbnail: data.thumbnail,
      });
      const imageUrl = await getImageUrl(data.thumbnail || '');
      console.log('imageUrl', imageUrl);
      setThumbnailUrl(imageUrl);
      // メニューアイコンが一瞬消える対策としてローカルストレージに保存
      localStorage.setItem('teamIconUrl', imageUrl);
      console.log('thumbnailUrl', thumbnailUrl);
    } catch (error) {
      Sentry.captureException(error);
      // console.log(error);
    }
  };

  const getTeamsInfo = async () => {
    try {
      const data = await fetchTeamsInfo();
      // console.log(data);
      // console.log(teamsInfo);
      if (teamsInfo.joinings.length === 0 && teamsInfo.invitations.length === 0) {
        setTeamsInfo({
          ...teamsInfo,
          invitations: [...teamsInfo.invitations, ...data.invitations],
          joinings: [...teamsInfo.joinings, ...data.joinings],
        });
      }
      // console.log(data.joinings);
      // console.log(teamsInfo.joinings);
    } catch (error) {
      Sentry.captureException(error);
      // console.log(error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getAccountInfo();
    }, 1100);
    // getAccountInfo();
    getTeamInfo();
    getTeamsInfo();
  }, []);

  return (
    <div className="top-0 flex h-full w-[62px] bg-black">
      <div className="flex h-[749px] flex-1 flex-col items-start justify-between self-stretch">
        <div className="flex flex-col items-center self-stretch">
          <div id="header" className="my-4 flex flex-col items-center justify-center self-stretch bg-black">
            <img className="h-full w-10" src={getImageUrl('assets/logo/column_white.png')} alt="" />
          </div>
          <div className="flex items-center justify-center self-stretch px-2 py-[10px]">
            <div className="relative flex justify-center">
              {thumbnailUrl ? (
                <DropDown
                  icon={thumbnailUrl}
                  title="チーム"
                  selected={selectedTeamInfo.teamId}
                  items={[...teamsInfo.joinings, ...teamsInfo.invitations].map((team) => {
                    return {
                      icon: team.thumbnail ? getImageUrl(team.thumbnail) : '',
                      label: team.teamName,
                      id: team.teamId,
                      href: `${ROUTES.HOME}?teamId=${team.teamId}`,
                    };
                  })}
                  className="fixed left-[65px] top-0 z-50 w-[224px] rounded-md border border-gray-100 bg-white"
                ></DropDown>
              ) : (
                <div className="size-8"></div>
              )}
            </div>
          </div>
          <div className="flex h-[343px] w-[45px] flex-col items-center gap-1 pt-4">
            <div
              className={clsx(
                'group relative flex w-[45px] items-center justify-center self-stretch p-2 ',
                props.selected == firstSidebarSelect.home ? 'rounded-md bg-[#FFFFFF]/[.14]' : ''
              )}
            >
              <Link to={ROUTES.HOME} className="flex items-center">
                <HomeIcon
                  width={24}
                  height={24}
                  fill={props.selected == firstSidebarSelect.home ? '#FFFFFF' : '#9CA3AF'}
                />
              </Link>
              <Tooltip
                tooltipText="ホーム"
                isSpeechBubble={false}
                variant="black"
                className="absolute left-[55px] z-50 hidden w-[68px] group-hover:inline-block"
                toolTipClassName="px-[12px] py-[9px] text-white text-sm leading-5 font-medium"
              ></Tooltip>
            </div>
            <div
              className={clsx(
                'group relative flex w-[45px] items-center justify-center self-stretch p-2 ',
                props.selected == firstSidebarSelect.calendar ? 'rounded-md bg-[#FFFFFF]/[.14]' : ''
              )}
            >
              <Link to={ROUTES.CALENDAR} className="flex items-center">
                <Calendar
                  width={24}
                  height={24}
                  fill={props.selected == firstSidebarSelect.calendar ? '#FFFFFF' : '#9CA3AF'}
                />
              </Link>
              <Tooltip
                tooltipText="案件管理"
                isSpeechBubble={false}
                variant="black"
                className="absolute left-[55px] z-50 hidden w-[82px] group-hover:inline-block"
                toolTipClassName="px-[12px] py-[9px] text-white text-sm leading-5 font-medium"
              ></Tooltip>
            </div>
            <div
              className={clsx(
                'group relative flex w-[45px] items-center justify-center self-stretch p-2 ',
                props.selected == firstSidebarSelect.analysis ? 'rounded-md bg-[#FFFFFF]/[.14]' : ''
              )}
            >
              <Link to={ROUTES.PROJECT_ANALYSIS_SPIRIT} className="flex items-center">
                <BarChart
                  width={24}
                  height={24}
                  fill={props.selected == firstSidebarSelect.analysis ? '#FFFFFF' : '#9CA3AF'}
                />
              </Link>
              <Tooltip
                tooltipText="分析"
                isSpeechBubble={false}
                variant="black"
                className="absolute left-[55px] z-50 hidden w-[54px] group-hover:inline-block"
                toolTipClassName="px-[12px] py-[9px] text-white text-sm leading-5 font-medium"
              ></Tooltip>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start self-stretch">
          <div className="flex flex-col items-start self-stretch py-1">
            <div className="group relative  flex items-center justify-center self-stretch p-2">
              <Link
                to={ROUTES.NORTIFICATION}
                className={clsx(
                  'group relative flex items-center justify-center self-stretch px-[10.5px] py-2',
                  props.selected == firstSidebarSelect.notification ? 'rounded-md bg-[#FFFFFF]/[.14]' : ''
                )}
                onClick={getAccountInfo}
              >
                {/* 未読数が0の場合は表示しない。1万件以上未読がある場合は、9,999と表示 */}
                {unread > 0 && unread < 10000 ? (
                  <div className="absolute left-6 top-1 flex h-[16px] items-center justify-center rounded-full bg-[#EF4444] p-[2px]">
                    <span className="h-3 min-w-[12px] text-center align-middle text-[10px] font-medium leading-[12.1px] text-white">
                      {unread.toLocaleString()}
                    </span>
                  </div>
                ) : unread >= 10000 ? (
                  <div className="absolute left-6 top-1 flex h-[16px] items-center justify-center rounded-full bg-[#EF4444] p-[2px]">
                    <span className="h-3 min-w-[12px] text-center align-middle text-[10px] font-medium leading-[12.1px] text-white">
                      9,999
                    </span>
                  </div>
                ) : null}
                <Bell
                  width={24}
                  height={24}
                  fill={props.selected == firstSidebarSelect.notification ? '#FFFFFF' : '#9CA3AF'}
                />
              </Link>
              <Tooltip
                tooltipText="通知"
                isSpeechBubble={false}
                variant="black"
                className="absolute left-[55px] z-50 hidden w-[54px] group-hover:inline-block"
                toolTipClassName="px-[12px] py-[9px] text-white text-sm leading-5 font-medium"
              ></Tooltip>
            </div>
            <div className="group relative flex items-center justify-center self-stretch p-2">
              <Link
                to={ROUTES.TEAM_BASIC_INFO}
                className={clsx(
                  'group relative flex items-center justify-center self-stretch p-2',
                  props.selected == firstSidebarSelect.basicInfo ? 'rounded-md bg-[#FFFFFF]/[.14]' : ''
                )}
              >
                <Cog
                  width={24}
                  height={24}
                  fill={props.selected == firstSidebarSelect.basicInfo ? '#FFFFFF' : '#9CA3AF'}
                />
              </Link>
              <Tooltip
                tooltipText="設定"
                isSpeechBubble={false}
                variant="black"
                className="absolute left-[55px] z-50 hidden w-[54px] group-hover:inline-block"
                toolTipClassName="px-[12px] py-[9px] text-white text-sm leading-5 font-medium"
              ></Tooltip>
            </div>
            <div className="group relative flex items-center justify-center self-stretch p-2">
              <Link
                to={process.env.REACT_APP_ZENDESK_URL ?? ''}
                target="_blank"
                className={clsx(
                  'group relative flex items-center justify-center self-stretch p-2',
                  props.selected == firstSidebarSelect.help ? 'rounded-md bg-[#FFFFFF]/[.14]' : ''
                )}
              >
                <img src={helpCircleImage} alt="" />
              </Link>
              <Tooltip
                tooltipText="ヘルプページ"
                isSpeechBubble={false}
                variant="black"
                className="absolute left-[55px] z-50 hidden w-[110px] group-hover:inline-block"
                toolTipClassName="px-[12px] py-[9px] text-white text-sm leading-5 font-medium"
              ></Tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
