import NoImage from '@/assets/images/NoImage.png';
import { getImageUrlIfRelative } from '@/utils/image';

type DetailImagesType = {
  isEdit: boolean;
  images: string[];
  editFunc: () => void;
};

export const DetailImages = (props: DetailImagesType) => {
  return (
    <div className="h-[176+48px] w-[726.5px] bg-white pl-[24px] pt-[48px]">
      <div className="flex h-[28px] w-[678.5px] justify-between">
        <div className="h-[28px] w-[344px] content-center">
          <span className="text-lg font-semibold leading-7 text-gray-700">詳細画像</span>
        </div>
        {props.isEdit && (
          <button
            className="right-4 bg-transparent text-blue-500"
            onClick={() => {
              props.editFunc();
            }}
          >
            編集
          </button>
        )}
      </div>
      <div className="flex h-[116+24px] w-[678.5px] gap-[24px] pt-[24px]">
        {Array.from({ length: 3 }, (_, index: number) =>
          props.images[index] ? getImageUrlIfRelative(props.images[index]) : NoImage
        ).map((val) => {
          return <img key={val + Math.random()} className="h-[116px] w-[174.12px]" src={val} alt="" />;
        })}
      </div>
    </div>
  );
};
