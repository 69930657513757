import { cloneDeep } from 'lodash';
import React from 'react';

import { ReactComponent as DownIcon } from '../assets/ChevronDown.svg';
import { ReactComponent as UpIcon } from '../assets/ChevronUp.svg';
import { ReactComponent as CopyIcon } from '../assets/Copy.svg';
import { ReactComponent as TrashIcon } from '../assets/Trash.svg';
import { SelectType } from '../PopupInterfaces';

export interface headerProps {
  title: string;
  isOpen: boolean;
  isTop: boolean;
  isLast: boolean;
  handleOpen: () => void;
  handleClose: () => void;
  selected: SelectType[] | undefined;
  index: number;
  setSelected: React.Dispatch<React.SetStateAction<SelectType[] | undefined>>;
}

export default function Header(props: headerProps): React.ReactElement {
  const handleOpneContent = () => {
    if (props.isOpen) {
      props.handleClose();
    } else {
      props.handleOpen();
    }
  };

  const delete_state = () => {
    if (props.selected) {
      for (let loop_num = 0; loop_num < props.selected.length; loop_num = loop_num + 1) {
        if (loop_num == props.index) {
          props.setSelected(props.selected.filter((s: any, i: any) => i !== loop_num));
        }
      }
    }
  };

  const copy_state = () => {
    if (props.selected) {
      for (let loop_num = 0; loop_num < props.selected.length; loop_num = loop_num + 1) {
        if (loop_num == props.index) {
          const new_selected: SelectType[] | undefined = [...props.selected];
          new_selected.splice(loop_num + 1, 0, cloneDeep(props.selected[loop_num]));
          props.setSelected(new_selected);
        }
      }
    }
  };

  return (
    <div className="flex h-[77px] w-[696px] flex-row items-center gap-4 rounded-[10px] border border-gray-200 bg-gray-50">
      <div className="flex h-[28px] w-[568px] flex-row items-center gap-2 pl-6 pr-[20px]">
        <span>{props.title}</span>
      </div>
      <div>
        <button onClick={copy_state}>
          <CopyIcon stroke="#6B7280BF" />
        </button>
      </div>
      <div>
        <button onClick={delete_state}>
          <TrashIcon stroke="#6B7280BF" />
        </button>
      </div>
      <div>
        <button onClick={handleOpneContent}>
          {props.isOpen ? <UpIcon stroke="#9CA3AF" /> : <DownIcon stroke="#9CA3AF" />}
        </button>
      </div>
    </div>
  );
}
