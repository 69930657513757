import { ProjectEstimate, ProjectEstimateItem } from '@/api/project';

export default function Estimate(props: ProjectEstimate | undefined) {
  // カンマを付与する
  const addComma = (target: number): string => `${String(target).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;

  return (
    <div className="flex pt-6">
      <div className="w-[726.5px] rounded-lg bg-white">
        <div className="px-6 py-4">
          <div className="pt-[16px]">
            <div className="text-base font-semibold leading-7 text-gray-700 ">お見積り</div>
          </div>
          <div className="w-[700px] pt-4">
            <span className="text-sm font-medium not-italic  text-gray-700">
              ※お見積りはご利用いただくプラン内容により、変動することおよび算出できないこと予めご了承ください。
              <br />
              最終確定金額は、見積書または請求書にて担当者からご連絡をさせていただきます。
            </span>
          </div>
          <div className="flex  h-[16+16px] w-[678.75px] pt-4 text-gray-500">
            <div className="flex h-[16px] w-[480px] basis-[442.25px]">
              <span className="text-xs font-normal leading-4  text-gray-500">詳細</span>
            </div>
            <div className="flex h-[16px] w-[40px] justify-end">
              <span className="text-xs font-normal leading-4 text-gray-500">数量</span>
            </div>
            <div className="flex h-[16px] w-[112px] justify-end">
              <span className="text-xs font-normal leading-4 text-gray-500">単価</span>
            </div>
            <div className="flex h-[16px] w-[112px] justify-end">
              <span className="text-xs font-normal leading-4 text-gray-500">金額</span>
            </div>
          </div>
          <div className="h-[1px] w-[678.75px] pt-[8px]">
            <div className="border-[1px] border-gray-200 "></div>
          </div>
          {props?.items.fixedItems.length || props?.items.optionFreeItems.length ? (
            <>
              {props.items.fixedItems
                .concat(props.items.optionFixedItems)
                .concat(props.items.optionFreeItems)
                .map((item: ProjectEstimateItem, index: number) => (
                  <div key={index} className="flex h-[16+16px] w-[678.75px] pt-[16px] text-gray-500">
                    <div className="flex h-[16px] w-[480px] basis-[442.25px]">
                      <span className="text-xs font-normal leading-4  text-gray-500">{item.name}</span>
                    </div>
                    <div className="flex h-[16px] w-[40px] justify-end">
                      <span className="text-xs font-normal leading-4 text-gray-500">
                        {addComma(item.counts) || '-'}
                      </span>
                    </div>
                    <div className="flex h-[16px] w-[112px] justify-end">
                      <span className="text-xs font-normal leading-4 text-gray-500">{addComma(item.price) || '-'}</span>
                    </div>
                    <div className="flex h-[16px] w-[112px] justify-end">
                      <span className="text-xs font-normal leading-4 text-gray-500">{addComma(item.total) || '-'}</span>
                    </div>
                  </div>
                ))}
            </>
          ) : (
            Array.from({ length: 4 }).map((_, index) => (
              <div className="flex h-[16+16px] w-[678.75px] pt-[16px] text-gray-500" key={index}>
                <div className="flex h-[16px] w-[480px] basis-[442.25px]">
                  <span className="text-xs font-normal leading-4  text-gray-500">-</span>
                </div>
                <div className="hß-[16px] flex w-[40px] justify-end">
                  <span className="text-xs font-normal leading-4 text-gray-500">-</span>
                </div>
                <div className="flex h-[16px] w-[112px] justify-end">
                  <span className="text-xs font-normal leading-4 text-gray-500">-</span>
                </div>
                <div className="flex h-[16px] w-[112px] justify-end">
                  <span className="text-xs font-normal leading-4 text-gray-500">-</span>
                </div>
              </div>
            ))
          )}
          <div className="h-[1px] w-[678.75px] pt-[8px]">
            <div className="border-[1px] border-gray-200 "></div>
          </div>
          <div className="flex flex-col justify-end pt-4">
            <div className="flex h-8 justify-end pt-4">
              <div className="flex h-[24px] flex-row items-center justify-between">
                <span className="text-xs font-normal leading-4 text-gray-500">小計</span>
              </div>
              <div className="h-[16px] w-[194px] justify-end"></div>
              <div className="h-[16px] justify-end">
                <span className="text-base font-semibold leading-6 text-gray-500">
                  {props?.subtotal ? '¥' + addComma(props?.subtotal) : '¥-'}
                </span>
              </div>
            </div>
            <div className="flex h-8 justify-end pt-4">
              <div className="flex h-[24px] flex-row items-center justify-between">
                <span className="text-xs font-normal leading-4 text-gray-500">消費税</span>
              </div>
              <div className="h-[16px] w-[190px] justify-end"></div>
              <div className="h-[16px] justify-end">
                <span className="text-base font-semibold leading-6 text-gray-500">
                  {props?.tax ? '¥' + addComma(props?.tax) : '¥-'}
                </span>
              </div>
            </div>
            <div className="flex h-8 justify-end pt-4">
              <div className="flex h-[24px] flex-row items-center justify-between">
                <span className="w-[100px] text-left text-sm font-semibold leading-5 text-gray-500">
                  合計金額(税込)
                </span>
              </div>
              <div className="flex h-[16px] w-[189px] justify-end"></div>
              <div className="flex h-[16px] justify-end">
                <span className="text-base font-semibold leading-6 text-gray-500">
                  {props?.total ? '¥' + addComma(props?.total) : '¥-'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
