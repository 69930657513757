import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import * as Sentry from '@sentry/react';
import React, { useEffect, Fragment, useState } from 'react';
import Dropzone from 'react-dropzone';

import Photo from '@/assets/images/Photo.png';
import { Badge } from '@/components/Elements/Badge';
import { Button } from '@/components/Elements/Button/Button';
import { Input } from '@/components/Elements/Input';
import { UPLOAD_FILE_TYPES } from '@/config';
import {
  validationMessageMaxNumCheck,
  validationMessageMinNumCheck,
  validationImageCheck,
  validationInputVoidCheck,
} from '@/utils/inputMultiValidationCheck';
import { ValidationMessages } from '@/utils/message';
export interface ProjectRecruitingState {
  open: boolean;
  title: string;
  sns: string;
  file: string;
  displayTitle: string;
  tags?: string[];
  tag?: string;
  displayTags?: string[];
  displayTag?: string;
  displayImage?: string;
  projectName?: string;
  titleError: boolean;
  tagError: boolean;
  imageError: boolean;
  validationTitleMessage: string;
  validationTagMessage: string;
  validationImageMessage: string;
}

interface ProjectRecruitingTitleProps {
  projectRecruitngState: ProjectRecruitingState;
  setProjectRecruitingState: React.Dispatch<React.SetStateAction<ProjectRecruitingState>>;
  PutTitle: (title: string, tags: string[], featuredImage?: File) => Promise<void>;
}

export const ProjectRecruitingTitle = (props: ProjectRecruitingTitleProps) => {
  const { open, displayTitle, file } = props.projectRecruitngState;
  const { setProjectRecruitingState } = props;
  const [apiSetFiles, setApiSetFiles] = useState<File[]>([]);

  useEffect(() => {
    console.log('ProjectRecruitingTitle.tsx: props:' + JSON.stringify(props, null, 2));
    console.log('ProjectRecruitingTitle.tsx: open:' + props.projectRecruitngState.file);

    if (!open || displayTitle === '-') return;
    setProjectRecruitingState((prevState: any) => ({
      ...prevState,
      title: displayTitle,
    }));
  }, [open, displayTitle, setProjectRecruitingState]);

  useEffect(() => {
    if (file === '-') return;
    setProjectRecruitingState((prevState: any) => ({
      ...prevState,
      file: props.projectRecruitngState.displayImage,
    }));
  }, []);

  const handleChangeFile = (files: File[], e: any) => {
    const MAX_DATASIZR = 30 * 1024 * 1024; // 30MB
    if (e[0] != undefined) {
      if (validationImageCheck(e[0].file) === false) {
        props.setProjectRecruitingState((prevState) => ({
          ...prevState,
          imageError: true,
          validationImageMessage: ValidationMessages.strImageMessage('アイキャッチ画像', 30),
        }));

        return;
      }
    }
    if (files[0].size > MAX_DATASIZR) {
      props.setProjectRecruitingState((prevState) => ({
        ...prevState,
        imageError: true,
        validationImageMessage: ValidationMessages.strImageMessage('アイキャッチ画像', 30),
      }));

      return;
    }

    props.setProjectRecruitingState((prevState) => ({
      ...prevState,
      imageError: false,
    }));

    const tmpFiles: File[] = [];

    files.forEach((file: File) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        props.setProjectRecruitingState((prevState) => ({
          ...prevState,
          file: reader.result as string,
        }));
      });

      if (file) {
        reader.readAsDataURL(file);
        tmpFiles.push(file);
      }
    });
    setApiSetFiles(tmpFiles);
    return;
  };
  const handleAdd = () => {
    if (
      props.projectRecruitngState.tag &&
      props.projectRecruitngState.tagError &&
      props.projectRecruitngState.tags?.length !== undefined &&
      props.projectRecruitngState.tags?.length <= 4
    ) {
      props.setProjectRecruitingState((prevState) => ({
        ...prevState,
        tagError: false,
      }));
      const addTag = props.projectRecruitngState.tag;
      props.setProjectRecruitingState((prevState) => ({
        ...prevState,
        tags: prevState.tags ? [...prevState.tags, addTag] : [addTag],
        tag: '',
        displayTags: prevState.displayTags ? [...prevState.displayTags, addTag] : [addTag],
      }));
    }

    if (
      !props.projectRecruitngState.tag ||
      !props.projectRecruitngState.tags ||
      props.projectRecruitngState.tag.length === 0 ||
      props.projectRecruitngState.tagError
    )
      return;

    if (props.projectRecruitngState.tags.length >= 5) {
      props.setProjectRecruitingState((prevState) => ({
        ...prevState,
        tagError: true,
        validationTagMessage: ValidationMessages.tagNumMessage(5),
      }));

      return;
    } else {
      props.setProjectRecruitingState((prevState) => ({
        ...prevState,
        tagError: false,
      }));
    }

    if (props.projectRecruitngState.tag) {
      const addTag = props.projectRecruitngState.tag;
      props.setProjectRecruitingState((prevState) => ({
        ...prevState,
        tags: prevState.tags ? [...prevState.tags, addTag] : [addTag],
        tag: '',
        displayTags: prevState.displayTags ? [...prevState.displayTags, addTag] : [addTag],
      }));
    }
  };

  const handleRemove = (index: number) => {
    if (!props.projectRecruitngState.tags) return;
    const tmpTags = props.projectRecruitngState.tags;
    props.setProjectRecruitingState((prevState) => ({
      ...prevState,
      tags: tmpTags.filter((_, i) => i !== index),
    }));

    if (props.projectRecruitngState.tags.length <= 5) {
      props.setProjectRecruitingState((prevState) => ({
        ...prevState,
        tagError: false,
      }));
    }

    // 以下がないと、タグを削除した際にエラーチェックが走らずに30文字以上のタグが追加できてしまう
    if (props.projectRecruitngState.tag) {
      if (validationMessageMinNumCheck(props.projectRecruitngState.tag, 31) == false) {
        props.setProjectRecruitingState((prevState) => ({
          ...prevState,
          tagError: true,
          validationTagMessage: ValidationMessages.strMaxLenMessage('タグ', 30),
        }));
      }
    }
  };

  const titleButtonValidationProcess = (e: React.ChangeEvent<HTMLInputElement> | string): boolean => {
    const target = typeof e == 'string' ? e : e.target.value;

    const updatedState = {
      titleError: props.projectRecruitngState.titleError,
      validationTitleMessage: '',
    };
    props.setProjectRecruitingState((prevState) => ({
      ...prevState,
      title: target,
    }));

    if (validationMessageMaxNumCheck(target, 100)) {
      updatedState.titleError = false;
    } else {
      updatedState.titleError = true;
      props.setProjectRecruitingState((prevState) => ({
        ...prevState,
        ...updatedState,
        validationTitleMessage: ValidationMessages.strMaxLenMessage('タイトル名', 100),
      }));
      return false;
    }

    if (validationInputVoidCheck(target) == false) {
      updatedState.titleError = true;
      updatedState.validationTitleMessage = ValidationMessages.inputMessage('タイトル名');
      props.setProjectRecruitingState((prevState) => ({
        ...prevState,
        ...updatedState,
      }));
      return false;
    } else {
      updatedState.titleError = false;
    }

    props.setProjectRecruitingState((prevState) => ({
      ...prevState,
      ...updatedState,
    }));
    return true;
  };

  const TagButtonValidationProcess = (e: React.ChangeEvent<HTMLInputElement> | string): boolean => {
    const target = typeof e == 'string' ? e : e.target.value;

    props.setProjectRecruitingState((prevState) => ({
      ...prevState,
      tag: target,
    }));

    const max_flag = validationMessageMinNumCheck(target, 31);
    if (max_flag) {
      props.setProjectRecruitingState((prevState) => ({
        ...prevState,
        tagError: false,
      }));
    } else {
      props.setProjectRecruitingState((prevState) => ({
        ...prevState,
        tagError: true,
        validationTagMessage: ValidationMessages.strMaxLenMessage('タグ', 30),
      }));
      return false;
    }

    return true;
  };

  const saveValidationCheck = () => {
    let validationFlag = true;
    if (
      titleButtonValidationProcess(props.projectRecruitngState.title ? props.projectRecruitngState.title : '') == false
    ) {
      validationFlag = false;
    }

    if (props.projectRecruitngState.tags?.length === 0) {
      validationFlag = false;
      props.setProjectRecruitingState((prevState) => ({
        ...prevState,
        tagError: true,
        validationTagMessage: ValidationMessages.tagAddMessage('タグ'),
      }));
    } else {
      props.setProjectRecruitingState((prevState) => ({
        ...prevState,
        tagError: false,
      }));
    }

    if (props.projectRecruitngState.file == undefined || props.projectRecruitngState.file == '') {
      validationFlag = false;
      props.setProjectRecruitingState((prevState) => ({
        ...prevState,
        imageError: true,
        validationImageMessage: ValidationMessages.strImageMessage('アイキャッチ画像', 30),
      }));
    }
    if (props.projectRecruitngState.titleError || props.projectRecruitngState.imageError) {
      validationFlag = false;
    }

    return validationFlag;
  };

  const closeProcess = () => {
    setApiSetFiles([]);
    console.log(props.projectRecruitngState);

    props.setProjectRecruitingState((prevState) => ({
      ...prevState,
      open: false,
      tag: props.projectRecruitngState.displayTag,
      title: props.projectRecruitngState.displayTitle,
      file: prevState.displayImage ? prevState.displayImage : '',
      tags: prevState.displayTags,
      titleError: false,
      tagError: false,
      imageError: false,
    }));
  };

  const saveDisplayVariables = async () => {
    const { title, tags } = props.projectRecruitngState;
    // 要素が1個という前提のため、複数存在する場合は修正必要
    const apiSetFile = apiSetFiles[0];

    try {
      // 条件に基づいてタイトル登録・更新APIを実行
      if (props.PutTitle) {
        await props.PutTitle(title, tags ?? [], apiSetFile);
      }

      props.setProjectRecruitingState((prevState) => ({
        ...prevState,
        open: false,
        tags: props.projectRecruitngState.tags,
        displayTitle: props.projectRecruitngState.title,
        displayTags: props.projectRecruitngState.displayTags,
        displayImage: props.projectRecruitngState.file,
        image: props.projectRecruitngState.file,
        tag: '',
        titleError: false,
        tagError: false,
        imageError: false,
      }));
    } catch (error) {
      Sentry.captureException(error);
      console.error('Error updating project title:', error);
    }
  };

  return (
    <Transition.Root show={props.projectRecruitngState.open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          closeProcess();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 opacity-30 transition-opacity" style={{ background: 'rgba(107, 114, 128)' }} />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative h-[670px] w-[768px] overflow-hidden rounded-lg bg-white pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                    onClick={() => {
                      closeProcess();
                    }}
                  >
                    <span className="sr-only">Close</span>
                    {<XMarkIcon className="h-[24px] w-[24px]" aria-hidden="false" />}
                  </button>
                </div>
                <div className="sm:flex  sm:items-start">
                  <div className="text-center sm:text-left">
                    <Dialog.Title as="h2" className="h-[24px] w-[696px] text-lg font-medium leading-6 text-gray-900">
                      案件募集タイトル
                    </Dialog.Title>
                    <div className="text-left">
                      <p className="text-sm text-gray-500">「※」が付いている項目は必須項目です</p>
                    </div>
                    <div className="pt-6 font-semibold">
                      <Input
                        id="title"
                        type="text"
                        label="タイトル名*"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => titleButtonValidationProcess(e)}
                        value={props.projectRecruitngState.title}
                        placeholder="タイトル名"
                        isPlaceHolderRight={false}
                        className={
                          (props.projectRecruitngState.titleError
                            ? 'border-red-400 outline-none ring-red-400 focus:ring-red-400 active:ring-red-400'
                            : '') + 'pl-[11px] focus:border-white'
                        }
                      ></Input>

                      {props.projectRecruitngState.titleError && (
                        <div className="pt-2 text-base text-red-400">
                          {props.projectRecruitngState.validationTitleMessage}
                        </div>
                      )}
                    </div>
                    {props.projectRecruitngState.projectName == 'spirit' ||
                    props.projectRecruitngState.projectName == 'prst' ||
                    props.projectRecruitngState.projectName == 'jane_john' ||
                    props.projectRecruitngState.projectName == 'other' ? (
                      <span>
                        <div className="pt-[24px]">
                          <div>
                            <span className="text-sm font-semibold leading-6 text-gray-700">タグ*</span>
                          </div>
                          <div>
                            <span className="text-sm leading-6 text-gray-500">
                              案件の特徴を表現するタグを最大5つまで設定できます
                            </span>
                          </div>
                          <div className="py-4">
                            <div className="flex">
                              <Input
                                label=""
                                style={{ width: '618px' }}
                                classnamelabel="text-sm font-semibold leading-5"
                                value={props.projectRecruitngState.tag}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  TagButtonValidationProcess(e);
                                }}
                                className={
                                  props.projectRecruitngState.tagError
                                    ? 'border-red-400 pl-[11px] outline-none ring-red-400 focus:border-red-400 focus:ring-red-400 active:ring-red-400'
                                    : 'pl-[11px]'
                                }
                              ></Input>

                              <div className="flex-col">
                                <Button
                                  variant="secoundary"
                                  onClick={() => handleAdd()}
                                  size="smNopadding"
                                  className="h-[38px] w-[62px] px-[5.5px]"
                                  disabled={props.projectRecruitngState.tagError}
                                >
                                  <span className="text-sm font-medium">追加</span>
                                </Button>
                              </div>
                            </div>

                            {props.projectRecruitngState.tagError && (
                              <div className="pt-2 text-base font-semibold text-red-400">
                                {props.projectRecruitngState.validationTagMessage}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="flex gap-[10px] ">
                          {props.projectRecruitngState.tags &&
                            props.projectRecruitngState.tags.map((tag, index) => {
                              return (
                                <div key={index} className="pb-2">
                                  <Badge
                                    color="gray"
                                    round="xl"
                                    size="xs"
                                    className="w-fit px-2 py-3"
                                    isRemovable={true}
                                    handleRemove={() => {
                                      handleRemove(index);
                                    }}
                                  >
                                    {tag}
                                  </Badge>
                                </div>
                              );
                            })}
                        </div>
                      </span>
                    ) : (
                      <> </>
                    )}
                    <div className="pt-[24px] text-sm font-semibold leading-5">アイキャッチ画像*</div>
                    <div>
                      <p className="pt-[4px] text-sm font-normal leading-5 text-gray-500">
                        案件のイメージを伝える画像になります。
                      </p>
                    </div>

                    <div>
                      <p className="pt-[4px] text-sm font-normal leading-5 text-gray-500">
                        <br />
                        ※表示に関する注意事項
                        <br /> アップロードされた画像は、アプリ内で自動的に3:2の比率で表示されます。
                        <br />
                        画像のアスペクト比が異なる場合、上下または左右に余白が追加されます。
                      </p>
                    </div>

                    <div className="h-[140px] w-[344px]">
                      <Dropzone
                        onDrop={(acceptedFiles, e) => handleChangeFile(acceptedFiles, e)}
                        accept={UPLOAD_FILE_TYPES}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div
                            className={`flex h-full w-full justify-center rounded-lg border border-dashed border-gray-900/25 ${
                              apiSetFiles.length === 0 ? 'items-center' : ''
                            }`}
                            {...getRootProps()}
                          >
                            <div className="flex w-full flex-col text-center">
                              <img
                                className={`mx-auto ${
                                  apiSetFiles.length === 0 ? 'h-9 w-9' : 'h-full w-full'
                                } rounded-lg object-contain text-gray-400`}
                                src={apiSetFiles.length === 0 ? Photo : URL.createObjectURL(apiSetFiles[0])}
                                alt=""
                              />
                              {apiSetFiles.length > 1 ? (
                                <>
                                  <div className="mt-4 flex justify-center text-sm leading-6 text-gray-600">
                                    <p className="pl-1 text-sm font-medium">ドラッグ&ドロップもしくは</p>
                                    <label
                                      htmlFor="file-upload"
                                      className="relative cursor-pointer rounded-md bg-white text-sm font-medium  focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                      style={{ color: '#007CC2' }}
                                    >
                                      <span>画像をアップロード</span>
                                      <input
                                        id="file-upload"
                                        name="file-upload"
                                        type="file"
                                        className="sr-only"
                                        {...getInputProps()}
                                      />
                                    </label>
                                  </div>
                                  <p className="text-xs leading-5 text-gray-600">
                                    対応ファイルは30MB以内のPNG, JPG, GIF{' '}
                                  </p>
                                </>
                              ) : (
                                <>
                                  <div className="mt-4 flex justify-center text-sm leading-6 text-gray-600">
                                    <p className="pl-1 text-sm font-medium">ドラッグ&ドロップもしくは</p>
                                    <label
                                      htmlFor="file-upload"
                                      className="relative cursor-pointer rounded-md bg-white text-sm font-medium  focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                      style={{ color: '#007CC2' }}
                                    >
                                      <span>画像をアップロード</span>
                                      <input
                                        id="file-upload"
                                        name="file-upload"
                                        type="file"
                                        className="sr-only"
                                        {...getInputProps()}
                                      />
                                    </label>
                                  </div>
                                  <p className="text-xs leading-5 text-gray-600">
                                    対応ファイルは30MB以内のPNG, JPG, GIF{' '}
                                  </p>
                                </>
                              )}
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </div>
                    {props.projectRecruitngState.imageError && (
                      <div className="pt-2 text-base font-bold text-red-400">
                        {props.projectRecruitngState.validationImageMessage}
                      </div>
                    )}
                  </div>
                </div>

                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <Button
                    className="inline-flex w-full justify-center rounded-md px-[17px] py-[9px] text-sm font-medium leading-5 text-white shadow-sm sm:ml-3 sm:w-auto"
                    onClick={() => {
                      if (saveValidationCheck() == false) return;
                      saveDisplayVariables();
                    }}
                  >
                    保存する
                  </Button>
                  <Button
                    variant="white"
                    className="inline-flex w-full justify-center rounded-md bg-white px-[17px] py-[9px] text-sm font-medium leading-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => {
                      closeProcess();
                    }}
                  >
                    キャンセル
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
