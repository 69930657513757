import { useState, useMemo, useEffect, useLayoutEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';

import { ENV_PREFIX } from '@/config';
import { AuthAPI } from '@/lib/auth';

import Layout from '../components/Layout';

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}
/**
 * ログイン画面の要素
 * @returns {React.ReactElement} ログイン画面
 */
export function SignIn(): React.ReactElement {
  const [showLoading, setShowLoading] = useState<boolean>(true);
  const [isShowAuth, setIsAshowAuth] = useState<boolean>(false);
  const navigate = useNavigate();
  const query = useQuery();
  const refFirstRef = useRef(true);
  useEffect(() => {
    const auth: AuthAPI = new AuthAPI('auth0Container');
    auth.hide();
    if (process.env.NODE_ENV === 'development') {
      if (refFirstRef.current) {
        refFirstRef.current = false;
        return;
      }
    }
    document.title = 'INFLUFECT 会員登録・ログイン';
  }, []);

  useLayoutEffect(() => {
    if (showLoading) {
      setShowLoading(false);
      return;
    }
    const tmpQueryLoginType = query.get('type');
    setTimeout(() => {
      const invite: string = query.get('invite') || '';
      const teamId: string = query.get('team_id') || '';
      const invitation: string = query.get('invitation') || '';
      const invitaionQuery = `invite=${invite}&team_id=${teamId}&invitation=${invitation}`;

      const auth: AuthAPI = new AuthAPI('auth0Container', invite.length > 0 ? invitaionQuery : undefined);
      (async () => {
        try {
          await auth.isAuthenticated();
          if (invite.length > 0) {
            navigate(`/auth/sign-in-post?${invitaionQuery}`, { state: { authChecked: true } });
          } else {
            navigate('/auth/sign-in-post', { state: { authChecked: true } });
          }
          return;
        } catch (error) {
          console.error('error:', error);
        }
        auth.login(tmpQueryLoginType ? tmpQueryLoginType : 'login');
        setIsAshowAuth(true);
      })();
    }, 1000);
  }, [showLoading]);

  return (
    <Layout showLoading={showLoading}>
      {process.env.NODE_ENV === ENV_PREFIX.PRODUCTION && process.env.REACT_APP_ENV_PREFIX === ENV_PREFIX.PRODUCTION && (
        <Helmet>
          <script>
            {`
            (function(w, d, s, l, i) {
              w[l] = w[l] || [];
              w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
              var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != 'dataLayer' ? '&l=' + l : '';
              j.async = true;
              j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
              f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', 'GTM-MQQKJNW');
          `}
          </script>
        </Helmet>
      )}
      <div className="flex h-screen w-screen justify-center">
        <div
          id="auth0Container"
          className={isShowAuth ? 'h-screen min-w-[408px]' : 'h-screen min-w-[408px] bg-slate-200'}
        ></div>
      </div>
    </Layout>
  );
}
