type DescriptionItemType = {
  title: string;
  value?: string | string[];
  isUnderLine?: boolean;
  isTagDisplay?: boolean;
};
export const DescriptionItem = (props: DescriptionItemType) => {
  return (
    <>
      <div className="flex flex-row items-center whitespace-pre pt-[16px]">
        <div className="min-h-[20px] w-[233px]">
          <span className="text-sm font-semibold leading-5 text-gray-500">{props.title}</span>
        </div>

        <div className="min-h-[20px] w-[445.75px]">
          {typeof props.value == 'string' ? (
            <p className="whitespace-pre-line text-sm leading-5 text-gray-900">{props.value}</p>
          ) : (
            props.value !== undefined &&
            props.value.map((item, index) => {
              return (
                <div key={index} className="pt-[10px]">
                  <div
                    className={`${
                      props.isTagDisplay
                        ? 'inline-flex items-center rounded-xl bg-gray-100 px-3 py-0.5 text-sm font-medium text-gray-800'
                        : 'whitespace-pre-line text-sm  leading-5 text-gray-900'
                    }`}
                  >
                    {props.isTagDisplay ? item : `・${item}`}
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>

      {props.isUnderLine == true ? (
        <div></div>
      ) : (
        <div className="h-[1px] w-[678.5px] pt-[16px]">
          <div className="border-[1px] border-gray-200"></div>
        </div>
      )}
    </>
  );
};
