import { Dialog, Transition } from '@headlessui/react';
import { PlusIcon } from '@heroicons/react/20/solid';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { AxiosResponse } from 'axios';
import { cloneDeep } from 'lodash';
import { Fragment, useState, useEffect } from 'react';

// import { validationTitleCheck } from '@/utils/inputMultiValidationCheck';

import { SnsType } from '@/api/project';
import {
  TaskInstagramFeed,
  SpiritTaskItem,
  SpiritTaskItemDetail,
  TaskInstagramStories,
  TaskSampling,
  TaskOptionItem,
  TaskEvent,
  TaskBuying,
  TaskWebApp,
  TaskInstagramReel,
  TaskTikTokFeed,
  GetSpiritDetailOutput,
  TaskXPost,
  TaskYoutubeFeed,
} from '@/api/spirit';
import { putSpiritOperationDetails } from '@/hooks/SPIRIT';

import Loop_Menus from './Loop_Menus';
import { ecValidationCheck } from './Operation/Buy/Ec';
import { storeValidationCheck } from './Operation/Buy/Store';
import { eventsValidationCheck } from './Operation/Events';
import { feedValidationCheck } from './Operation/Instagram/Feed';
import { reelValidationCheck } from './Operation/Instagram/Reel';
import { storiesValidationCheck } from './Operation/Instagram/Stories';
import { samplingValidationCheck } from './Operation/Sampling';
import { tiktokValidationCheck } from './Operation/TikTok';
import { postValidationCheck } from './Operation/Twitter/Post';
import { webServiceValidationCheck } from './Operation/WebService';
import { youtubeValidationCheck } from './Operation/Youtube';
import { SNS_MENUES, SelectType } from './PopupInterfaces';
import SelectMenu from './SelectMenu';

export interface ProjectOperationDetailsProps {
  open: boolean;
  snsMenuOpen: boolean;
  selected: SelectType[] | undefined;
  menuId: number;
  sns: SnsType;
  projectId: string;
}

export interface Props {
  Popup_DetailArgs: ProjectOperationDetailsProps;
  setPopup_DetailArgs: React.Dispatch<React.SetStateAction<ProjectOperationDetailsProps>>;
  setProjectDetailSpirit: React.Dispatch<React.SetStateAction<GetSpiritDetailOutput>>;
  fetchSpiritProjectDetail: () => Promise<AxiosResponse<GetSpiritDetailOutput, any> | undefined>;
}

export function ProjectSplitOperationDetails(props: Props) {
  const [selected, setSelected] = useState<SelectType[] | undefined>(cloneDeep(props.Popup_DetailArgs.selected));

  useEffect(() => {
    if (props.Popup_DetailArgs.open) {
      const selectedItems = cloneDeep(props.Popup_DetailArgs.selected);
      const selectedItemsIncludingSortID = selectedItems?.map((selectItem) => {
        selectItem['sortId'] = crypto.randomUUID();
        return selectItem;
      });
      setSelected(cloneDeep(selectedItemsIncludingSortID));
    }
  }, [props.Popup_DetailArgs.open]);

  const dateToString = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const createTaskInstagramFeed = (item: SelectType): TaskInstagramFeed => {
    let startedAt = '';
    if (item.postPeriodBegin) {
      startedAt = dateToString(item.postPeriodBegin);
    }
    let endedAt = '';
    if (item.postPeriodEnd) {
      endedAt = dateToString(item.postPeriodEnd);
    }

    const requiredHashTags: string[] = item.hashTags.filter((tag) => tag !== '');
    const optionalHashTags: string[] = item.optionalHashTags.filter((tag) => tag !== '');
    const accountTags: string[] = item.accountTags.filter((tag) => tag !== '');

    const instagramFeedDetail: TaskInstagramFeed = {
      imageNum: item.postImageCount,
      movieNum: item.postMovieCount,
      location: item.locationInfo == '' ? null : item.locationInfo,
      isSecretLocation: item.showToHireeOnlyLocationInfo,
      startedAt: startedAt,
      endedAt: endedAt,
      body: item.postRequestContent,
      postingAd: item.advertisement,
      requiredHashTags: requiredHashTags.length > 0 ? requiredHashTags : null,
      OptionalHashTags: optionalHashTags.length > 0 ? optionalHashTags : null,
      isSecretHashTag: item.showToHireeOnlyHashTags,
      accountTags: accountTags.length > 0 ? accountTags : null,
      isSecretAccountTag: item.showToHireeOnlyAccountTags,
    };
    return instagramFeedDetail;
  };

  const createTaskInstagramStories = (item: SelectType): TaskInstagramStories => {
    let startedAt = '';
    if (item.postPeriodBegin) {
      startedAt = dateToString(item.postPeriodBegin);
    }
    let endedAt = '';
    if (item.postPeriodEnd) {
      endedAt = dateToString(item.postPeriodEnd);
    }

    const requiredHashTags: string[] = item.hashTags.filter((tag) => tag !== '');
    const optionalHashTags: string[] = item.optionalHashTags.filter((tag) => tag !== '');
    const accountTags: string[] = item.accountTags.filter((tag) => tag !== '');

    const taskInstagramStories: TaskInstagramStories = {
      location: item.locationInfo == '' ? null : item.locationInfo,
      isSecretLocation: item.showToHireeOnlyLocationInfo,
      startedAt: startedAt,
      endedAt: endedAt,
      body: item.postRequestContent,
      postingAd: item.advertisement,
      requiredHashTags: requiredHashTags.length > 0 ? requiredHashTags : null,
      OptionalHashTags: optionalHashTags.length > 0 ? optionalHashTags : null,
      isSecretHashTag: item.showToHireeOnlyHashTags,
      accountTags: accountTags,
      isSecretAccountTag: item.showToHireeOnlyAccountTags,
      highlight: item.highlight === '0' ? false : true,
      publicationWeek: item.publicWeek,
      isSecretUrl: item.showToHireeOnlyPostUrl,
      url: item.postUrl,
    };
    return taskInstagramStories;
  };

  const createTaskInstagramReel = (item: SelectType): TaskInstagramReel => {
    let startedAt = '';
    if (item.postPeriodBegin) {
      startedAt = dateToString(item.postPeriodBegin);
    }
    let endedAt = '';
    if (item.postPeriodEnd) {
      endedAt = dateToString(item.postPeriodEnd);
    }

    const requiredHashTags: string[] = item.hashTags.filter((tag) => tag !== '');
    const optionalHashTags: string[] = item.optionalHashTags.filter((tag) => tag !== '');
    const accountTags: string[] = item.accountTags.filter((tag) => tag !== '');

    const taskInstagramStories: TaskInstagramReel = {
      location: item.locationInfo == '' ? null : item.locationInfo,
      isSecretLocation: item.showToHireeOnlyLocationInfo,
      startedAt: startedAt,
      endedAt: endedAt,
      body: item.postRequestContent,
      postingAd: item.advertisement,
      requiredHashTags: requiredHashTags.length > 0 ? requiredHashTags : null,
      OptionalHashTags: optionalHashTags.length > 0 ? optionalHashTags : null,
      isSecretHashTag: item.showToHireeOnlyHashTags,
      accountTags: accountTags,
      isSecretAccountTag: item.showToHireeOnlyAccountTags,
    };
    return taskInstagramStories;
  };
  const createTaskTiktokFeed = (item: SelectType): TaskTikTokFeed => {
    console.log(item);

    let startedAt = '';
    if (item.postPeriodBegin) {
      startedAt = dateToString(item.postPeriodBegin);
    }
    let endedAt = '';
    if (item.postPeriodEnd) {
      endedAt = dateToString(item.postPeriodEnd);
    }

    const requiredHashTags: string[] = item.hashTags.filter((tag) => tag !== '');
    const optionalHashTags: string[] = item.optionalHashTags.filter((tag) => tag !== '');
    const accountTags: string[] = item.accountTags.filter((tag) => tag !== '');

    const tiktokFeedDetail: TaskTikTokFeed = {
      location: item.locationInfo == '' ? null : item.locationInfo,
      isSecretLocation: item.showToHireeOnlyLocationInfo,
      startedAt: startedAt,
      endedAt: endedAt,
      body: item.postRequestContent,
      postingAd: item.advertisement,
      requiredHashTags: requiredHashTags.length > 0 ? requiredHashTags : null,
      OptionalHashTags: optionalHashTags.length > 0 ? optionalHashTags : null,
      isSecretHashTag: item.showToHireeOnlyHashTags,
      accountTags: accountTags.length > 0 ? accountTags : null,
      isSecretAccountTag: item.showToHireeOnlyAccountTags,
      url: item.postUrl,
      isSecretUrl: item.showToHireeOnlyPostUrl,
    };
    return tiktokFeedDetail;
  };

  const createTaskX = (item: SelectType): TaskXPost => {
    let startedAt = '';
    if (item.postPeriodBegin) {
      startedAt = dateToString(item.postPeriodBegin);
    }
    let endedAt = '';
    if (item.postPeriodEnd) {
      endedAt = dateToString(item.postPeriodEnd);
    }

    const requiredHashTags: string[] = item.hashTags.filter((tag) => tag !== '');
    const optionalHashTags: string[] = item.optionalHashTags.filter((tag) => tag !== '');
    const accountTags: string[] = item.accountTags.filter((tag) => tag !== '');

    const taskXPost: TaskXPost = {
      startedAt: startedAt,
      endedAt: endedAt,
      body: item.postRequestContent,
      postingAd: item.advertisement,
      requiredHashTags: requiredHashTags.length > 0 ? requiredHashTags : null,
      OptionalHashTags: optionalHashTags.length > 0 ? optionalHashTags : null,
      isSecretHashTag: item.showToHireeOnlyHashTags,
      accountTags: accountTags.length > 0 ? accountTags : null,
      isSecretAccountTag: item.showToHireeOnlyAccountTags,
      postMethod: Number(item.postMethod),
      imageNum: item.postImageCount,
      movieNum: item.postMovieCount,
      url: item.postUrl == '' ? null : item.postUrl,
      isSecretUrl: item.showToHireeOnlyPostUrl,
    };

    return taskXPost;
  };

  const createTaskYoutube = (item: SelectType): TaskYoutubeFeed => {
    let startedAt = '';
    if (item.postPeriodBegin) {
      startedAt = dateToString(item.postPeriodBegin);
    }
    let endedAt = '';
    if (item.postPeriodEnd) {
      endedAt = dateToString(item.postPeriodEnd);
    }

    const requiredHashTags: string[] = item.hashTags.filter((tag) => tag !== '');
    const optionalHashTags: string[] = item.optionalHashTags.filter((tag) => tag !== '');
    const accountTags: string[] = item.accountTags.filter((tag) => tag !== '');

    const taskYoutubeFeed: TaskYoutubeFeed = {
      startedAt: startedAt,
      endedAt: endedAt,
      body: item.postRequestContent,
      postingAd: item.advertisement,
      requiredHashTags: requiredHashTags.length > 0 ? requiredHashTags : null,
      OptionalHashTags: optionalHashTags.length > 0 ? optionalHashTags : null,
      isSecretHashTag: item.showToHireeOnlyHashTags,
      accountTags: accountTags.length > 0 ? accountTags : null,
      isSecretAccountTag: item.showToHireeOnlyAccountTags,
      movieType: Number(item.movieType),
      url: item.postUrl == '' ? null : item.postUrl,
      isSecretUrl: item.showToHireeOnlyPostUrl,
    };

    return taskYoutubeFeed;
  };

  const createTaskSampling = (item: SelectType): TaskSampling => {
    const options: TaskOptionItem[] = [];
    if (item.productDetails) {
      item.productDetails.forEach((option) => {
        const taskOptionItem: TaskOptionItem = {
          description: option.itemTitle,
          options: option.options,
          selectable: option.allowInfluencerProductPickup == '0' ? true : false,
          amount: Number(option.maxSelectionCount),
        };
        options.push(taskOptionItem);
      });
    }

    let arrivalDate = '';
    if (item.scheduledDeliveryDate) {
      arrivalDate = dateToString(item.scheduledDeliveryDate);
    }

    const taskSampling: TaskSampling = {
      title: item.title,
      arrivalDate: arrivalDate,
      summaryText: item.productInfo,
      options: options,
    };
    return taskSampling;
  };

  const createTaskEvent = (item: SelectType): TaskEvent => {
    const options: TaskOptionItem[] = [];
    if (item.selectLists) {
      item.selectLists.forEach((option) => {
        const taskOptionItem: TaskOptionItem = {
          description: option.selectExplanation,
          options: option.selectType,
          amount: Number(option.maxSelectionCount),
        };
        options.push(taskOptionItem);
      });
    }

    let startedAt = '';
    if (item.eventDatesStart) {
      startedAt = dateToString(item.eventDatesStart);
    }
    let endedAt = '';
    if (item.eventDatesEnd) {
      endedAt = dateToString(item.eventDatesEnd);
    }

    const taskEvent: TaskEvent = {
      title: item.title,
      startedAt: startedAt,
      endedAt: endedAt,
      description: item.eventDetails,
      options: options,
    };

    return taskEvent;
  };

  const createTaskBuyingShop = (item: SelectType): TaskBuying => {
    const options: TaskOptionItem[] = [];
    if (item.productDetails) {
      item.productDetails.forEach((option) => {
        const taskOptionItem: TaskOptionItem = {
          description: option.itemTitle,
          options: option.options,
          amount: Number(option.maxSelectionCount),
          selectable: option.allowInfluencerProductPickup === '0' ? true : false,
        };
        options.push(taskOptionItem);
      });
    }

    let startedAt = '';
    if (item.buyingPeriodStart) {
      startedAt = dateToString(item.buyingPeriodStart);
    }
    let endedAt = '';
    if (item.buyingPeriodEnd) {
      endedAt = dateToString(item.buyingPeriodEnd);
    }

    const taskBuying: TaskBuying = {
      title: item.title,
      startedAt: startedAt,
      endedAt: endedAt,
      description: item.purchaseItemExplanation,
      shopInformation: item.storeInfo,
      isEvidence: item.purchaseEvidenceSettings === '2' ? true : false,
      evidenceNotice: item.purchaseEvidenceSettings === '2' ? item.purchaseEvidenceText : null,
      price: Number(item.purchaseItemPrice),
      options: options,
    };

    return taskBuying;
  };

  const createTaskBuyingEc = (item: SelectType): TaskBuying => {
    const options: TaskOptionItem[] = [];
    if (item.productDetails) {
      item.productDetails.forEach((option) => {
        const taskOptionItem: TaskOptionItem = {
          description: option.itemTitle,
          options: option.options,
          amount: Number(option.maxSelectionCount),
          selectable: option.allowInfluencerProductPickup === '0' ? true : false,
        };
        options.push(taskOptionItem);
      });
    }

    let startedAt = '';
    if (item.buyingPeriodStart) {
      startedAt = dateToString(item.buyingPeriodStart);
    }
    let endedAt = '';
    if (item.buyingPeriodEnd) {
      endedAt = dateToString(item.buyingPeriodEnd);
    }

    const taskBuying: TaskBuying = {
      title: item.title,
      startedAt: startedAt,
      endedAt: endedAt,
      description: item.purchaseItemExplanation,
      ecUrl: item.shoppingUrl,
      isEvidence: item.purchaseEvidenceSettings === '2' ? true : false,
      evidenceNotice: item.purchaseEvidenceSettings === '2' ? item.purchaseEvidenceText : null,
      price: Number(item.purchaseItemPrice),
      options: options,
    };

    return taskBuying;
  };

  const createTaskWebApp = (item: SelectType): TaskWebApp => {
    const options: TaskOptionItem[] = [];
    if (item.selectLists) {
      item.selectLists.forEach((option) => {
        const taskOptionItem: TaskOptionItem = {
          description: option.selectExplanation,
          options: option.selectType,
          amount: Number(option.maxSelectionCount),
          selectable: true,
        };
        options.push(taskOptionItem);
      });
    }

    let startedAt = '';
    if (item.downloadUsagePeriodStart) {
      startedAt = dateToString(item.downloadUsagePeriodStart);
    }
    let endedAt = '';
    if (item.downloadUsagePeriodEnd) {
      endedAt = dateToString(item.downloadUsagePeriodEnd);
    }

    const taskWebApp: TaskWebApp = {
      title: item.title,
      startedAt: startedAt,
      endedAt: endedAt,
      downloadUrl: item.downloadURL,
      isEvidence: item.purchaseEvidenceSettings === '2' ? true : false,
      evidenceNotice: item.purchaseEvidenceSettings === '2' ? item.purchaseEvidenceText : null,
      description: item.detail,
      options: options,
    };

    return taskWebApp;
  };

  const validation = () => {
    if (!selected) return false;

    let validResult = true;

    for (let i = 0; i < selected.length; i++) {
      if (selected[i].name === SNS_MENUES[10]) {
        validResult = feedValidationCheck(selected, i, setSelected, validResult);
      } else if (selected[i].name === SNS_MENUES[11]) {
        validResult = storiesValidationCheck(selected, i, setSelected, validResult);
      } else if (selected[i].name === SNS_MENUES[12]) {
        validResult = reelValidationCheck(selected, i, setSelected, validResult);
      } else if (selected[i].name === SNS_MENUES[1]) {
        validResult = samplingValidationCheck(selected, i, setSelected, validResult);
      } else if (selected[i].name === SNS_MENUES[2]) {
        validResult = storeValidationCheck(selected, i, setSelected, validResult);
      } else if (selected[i].name === SNS_MENUES[3]) {
        validResult = ecValidationCheck(selected, i, setSelected, validResult);
      } else if (selected[i].name === SNS_MENUES[4]) {
        validResult = eventsValidationCheck(selected, i, setSelected, validResult);
      } else if (selected[i].name === SNS_MENUES[5]) {
        validResult = webServiceValidationCheck(selected, i, setSelected, validResult);
      } else if (selected[i].name === SNS_MENUES[20] && selected[i].key === SnsType.X) {
        validResult = postValidationCheck(selected, i, setSelected, validResult);
      } else if (selected[i].name === SNS_MENUES[21] && selected[i].key === SnsType.X) {
        validResult = postValidationCheck(selected, i, setSelected, validResult);
      } else if (selected[i].name === SNS_MENUES[30] && selected[i].key === SnsType.TikTok) {
        validResult = tiktokValidationCheck(selected, i, setSelected, validResult);
      } else if (selected[i].name === SNS_MENUES[40] && selected[i].key === SnsType.Youtube) {
        validResult = youtubeValidationCheck(selected, i, setSelected, validResult);
      }
    }
    return validResult;
  };

  const changeState = async () => {
    // selectedをループで回して、SpiritTaskItemの配列を作成する
    const putSpiritOperationDetailsInput: Array<SpiritTaskItem> = [];
    if (selected) {
      selected.forEach((item) => {
        let itemDetail: SpiritTaskItemDetail;
        let taskType: number;
        switch (item.name) {
          case SNS_MENUES[1]:
            itemDetail = createTaskSampling(item);
            taskType = 1;
            break;
          case SNS_MENUES[2]:
            itemDetail = createTaskBuyingShop(item);
            taskType = 2;
            break;
          case SNS_MENUES[3]:
            itemDetail = createTaskBuyingEc(item);
            taskType = 3;
            break;
          case SNS_MENUES[4]:
            itemDetail = createTaskEvent(item);
            taskType = 4;
            break;
          case SNS_MENUES[5]:
            itemDetail = createTaskWebApp(item);
            taskType = 5;
            break;
          case SNS_MENUES[10]:
            itemDetail = createTaskInstagramFeed(item);
            taskType = 10;
            break;
          case SNS_MENUES[11]:
            itemDetail = createTaskInstagramStories(item);
            taskType = 11;
            break;
          case SNS_MENUES[12]:
            itemDetail = createTaskInstagramReel(item);
            taskType = 12;
            break;
          case SNS_MENUES[20]:
            itemDetail = createTaskX(item);
            taskType = 20;
            break;
          case SNS_MENUES[21]:
            itemDetail = createTaskX(item);
            taskType = 21;
            break;
          case SNS_MENUES[30]:
            itemDetail = createTaskTiktokFeed(item); // TODO Tiktok
            taskType = 30;
            break;
          case SNS_MENUES[40]:
            itemDetail = createTaskYoutube(item); // TODO Youtube
            taskType = 40;
            break;
          default:
            itemDetail = createTaskEvent(item);
            taskType = 4;
            break;
        }

        const spiritTaskItem: SpiritTaskItem = {
          taskId: null,
          taskType: taskType,
          detail: itemDetail,
        };
        putSpiritOperationDetailsInput.push(spiritTaskItem);
      });
    }
    const response = await putSpiritOperationDetails(props.Popup_DetailArgs.projectId, putSpiritOperationDetailsInput);
    props.setProjectDetailSpirit(response);

    props.setPopup_DetailArgs({
      ...props.Popup_DetailArgs,
      open: false,
      selected: cloneDeep(selected),
    });
  };

  const isSnsMenuOpen = () => {
    props.setPopup_DetailArgs({
      ...props.Popup_DetailArgs,
      snsMenuOpen: true,
    });
  };

  const isModalClose = () => {
    setSelected(cloneDeep(props.Popup_DetailArgs.selected));
    props.setPopup_DetailArgs({
      ...props.Popup_DetailArgs,
      open: false,
    });
  };

  const instagram_menus = [0, 10, 11, 12, 1, 2, 3, 4, 5].map((key) => SNS_MENUES[key]);
  const twitter_menus = [0, 20, 21, 1, 2, 3, 4, 5].map((key) => SNS_MENUES[key]);
  const tiktok_menus = [0, 30, 1, 2, 3, 4, 5].map((key) => SNS_MENUES[key]);
  const youtube_menus = [0, 40, 1, 2, 3, 4, 5].map((key) => SNS_MENUES[key]);

  const selectMenu =
    props.Popup_DetailArgs.sns == SnsType.Instagram
      ? instagram_menus
      : props.Popup_DetailArgs.sns == SnsType.X
      ? twitter_menus
      : props.Popup_DetailArgs.sns == SnsType.TikTok
      ? tiktok_menus
      : props.Popup_DetailArgs.sns == SnsType.Youtube
      ? youtube_menus
      : [];

  return (
    <Transition.Root show={props.Popup_DetailArgs.open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => props.setPopup_DetailArgs((prev) => ({ ...prev, open: false }))}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 opacity-30 transition-opacity" style={{ background: 'rgba(107, 114, 128)' }} />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-[768px] overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => {
                      isModalClose();
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-[24px] w-[24px]" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="text-center sm:text-left">
                    <Dialog.Title as="h2" className="h-[24px] w-[696px] text-lg font-medium leading-6 text-gray-900">
                      稼働内容詳細
                    </Dialog.Title>
                    <div className="py-2 text-left">
                      <p className="text-sm text-gray-500">「※」が付いている項目は必須項目です</p>
                    </div>
                    <div className="text-left">
                      <p className="text-sm font-normal leading-5 text-gray-500">
                        稼働の増減により、自動で価格は変動いたします
                      </p>
                    </div>
                    <div>
                      <div className="pt-[24px] text-sm font-semibold leading-5 text-gray-700">
                        必要な稼働を選んでください*
                      </div>
                      <div className="mb-3 pt-[4px] text-sm font-medium leading-5 text-gray-500">
                        稼働は複数選択可能です。
                      </div>
                      <div>
                        {/*Loop_Menus(Popup_DetailArgs.selected, Popup_DetailArgs.setSelected)*/}
                        {/* props の object の中に selected を入れて setSelected を修正必要 */}
                        {Loop_Menus(selected, setSelected)}
                      </div>
                    </div>

                    <div className="pt-[25px]">
                      <button
                        type="button"
                        className="inline-flex h-[38px] w-[158px] items-center gap-x-2 rounded-md bg-white px-3 text-sm font-medium text-blue-500 shadow-sm ring-1 ring-blue-500 hover:bg-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={isSnsMenuOpen}
                      >
                        <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                        稼働を追加する
                      </button>
                      {props.Popup_DetailArgs.snsMenuOpen ? (
                        <div className="py-[20px]">
                          {SelectMenu(
                            selectMenu,
                            selected,
                            setSelected,
                            props.Popup_DetailArgs.menuId,
                            props.Popup_DetailArgs.sns,
                            true,
                            props.setPopup_DetailArgs
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md px-[17px] py-[9px] text-sm font-medium leading-5 text-white shadow-sm sm:ml-3 sm:w-auto"
                    style={{ background: '#007CC2' }}
                    onClick={() => {
                      // 特別 Alert.tsx を表示させる
                      if (validation()) changeState();
                    }}
                  >
                    保存する
                  </button>

                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-[17px] py-[9px] text-sm font-medium leading-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => {
                      isModalClose();
                    }}
                  >
                    キャンセル
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
