import { ProjectSummaryDetails } from '@/api/other';

import { DescriptionItem } from './DescriptionItem';

type DetailSettingType = {
  isEdit: boolean;
  detail: ProjectSummaryDetails;
  editFunc: () => void;
};

export const DetailSetting = (props: DetailSettingType) => {
  return (
    <div className="h-[326+40px] w-[726.5px] gap-[16px] bg-white pl-[24px] pt-[40px]">
      <div className="flex h-[28px] w-[678.5px] justify-between">
        <div className="h-[28px] w-[344px] content-center">
          <span className="text-lg font-semibold leading-7 text-gray-700">詳細設定</span>
        </div>
        {props.isEdit && (
          <button
            className="right-4 bg-transparent text-blue-500"
            onClick={() => {
              props.editFunc();
            }}
          >
            編集
          </button>
        )}
      </div>
      <div className="h-[1px] w-[678.5px] pt-[16px]">
        <div className="border-[1px] border-gray-200"></div>
      </div>
      <DescriptionItem title="注意事項" value={props.detail.notice ?? '-'} />
      <DescriptionItem
        title="企業名公開"
        value={props.detail.isSecret ? '採用者のみ公開' : '募集時から全体に公開'}
        isUnderLine={true}
      />
    </div>
  );
};
