import Help from '@/assets/icons/bx-help-circle.png';
import { Tooltip } from '@/components/Elements/Tooltip/Tooltip';
import {
  OptionAllType,
  OptionMapType,
  SortByOption,
} from '@/components/ProjectManagements/Applicant/Contents/common/Types/ArgsType';

interface Props {
  option: OptionAllType;
  optionMap: OptionMapType;
}

export default function ProjectOverview(props: Props) {
  const isOptionKey = (key: string): key is Exclude<keyof OptionAllType, 'defaultOption'> => {
    return Object.keys(props.option)
      .filter((val) => val !== 'defaultOption')
      .includes(key);
  };
  const optionKeys: Map<number, keyof OptionAllType> = new Map(
    Object.keys(props.option).map((key, index) => {
      const setKey = isOptionKey(key) ? key : 'defaultOption';
      return [index, setKey];
    })
  );

  return (
    <div>
      {SortByOption(Array.from(optionKeys.entries())).map(([, key]) => {
        if (key === 'defaultOption') return null;
        const arg = props.optionMap.get(key);
        if (arg === undefined) return null;
        return (
          <>
            <div className="h-[1px] w-[678.75px]">
              <div className="border-[1px] border-gray-200"></div>
            </div>
            <div className="flex items-center whitespace-pre py-[16px]" key={key}>
              <div className="flex min-h-[20px] w-[233px] items-center gap-[5.33px]">
                <div className="text-sm font-semibold leading-5 text-gray-500">{arg ? arg.title : ''}</div>
                <div className="group relative pl-[7.33px]">
                  <img className="h-[16px] w-[16px]" src={Help} alt="" />
                  {arg.tipContent && (
                    <Tooltip
                      isSpeechBubble={true}
                      variant="gray"
                      toolTipClassName="px-3 py-2"
                      tooltipText={arg.tipContent}
                      className="absolute bottom-[18px] left-[-36px] hidden w-[500px] whitespace-normal text-xs group-hover:inline-block"
                    ></Tooltip>
                  )}
                </div>
              </div>
              <div className="min-h-[20px] w-[445.75px] ">
                <div className="whitespace-pre-wrap break-words text-sm font-semibold leading-5">
                  {props.option[key] === null || props.option[key] === undefined
                    ? '-'
                    : arg.radioValues.filter((obj) => obj.value === props.option[key]?.toString())[0].label}
                </div>
              </div>
            </div>
          </>
        );
      })}
      <div className="h-[1px] w-[678.75px]">
        <div className="border-[1px] border-gray-200"></div>
      </div>
    </div>
  );
}
