import { DndContext } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';

import { useAssetDataJson } from '@/hooks/AssetDataJson';

import Ec from './Operation/Buy/Ec';
import Store from './Operation/Buy/Store';
import Events from './Operation/Events';
import Feed from './Operation/Instagram/Feed';
import Reel from './Operation/Instagram/Reel';
import Stories from './Operation/Instagram/Stories';
import Sampling from './Operation/Sampling';
import TikTok from './Operation/TikTok';
import Post from './Operation/Twitter/Post';
import RePost from './Operation/Twitter/RePost';
import WebService from './Operation/WebService';
import Youtube from './Operation/Youtube';
import { SNS_MENUES, SelectType } from './PopupInterfaces';

export default function Loop_Menus(
  selected: SelectType[] | undefined,
  setSelected: React.Dispatch<React.SetStateAction<SelectType[] | undefined>>
) {
  const advertisementOption = useAssetDataJson('/advertisement') ?? [];
  return (
    <>
      {selected && (
        <div>
          <DndContext
            onDragEnd={(e) => {
              const currentIndex = selected.findIndex((value) => value.sortId == e.active.id);
              const currentItem = selected[currentIndex];
              const array = [...selected];
              // 変更対象を一旦要素から削除する
              array.splice(currentIndex, 1);
              // 入れ替え対象の要素のIndexを取得
              const targetIndex = selected.findIndex((value) => {
                if (!e.over) return false;
                return e.over.id == value.sortId;
              });
              // 入れ替え対象が存在しない場合は処理を終了する
              if (targetIndex < 0) {
                return;
              }
              // 入れ替え対象のIndexに要素を追加する
              array.splice(targetIndex, 0, currentItem);
              setSelected(array);
            }}
          >
            <SortableContext items={selected.map((v) => v.sortId)}>
              {selected.map((value, index) => {
                if (value.name == SNS_MENUES[1]) {
                  return (
                    <div className="my-[16px]" key={value.sortId}>
                      {Sampling(selected, index, setSelected, index === 0, index === selected.length - 1)}
                    </div>
                  );
                } else if (value.name == SNS_MENUES[2]) {
                  return (
                    <div className="my-[16px]" key={value.sortId}>
                      {Store(selected, index, setSelected, index === 0, index === selected.length - 1)}
                    </div>
                  );
                } else if (value.name == SNS_MENUES[3]) {
                  return (
                    <div className="my-[16px]" key={value.sortId}>
                      {Ec(selected, index, setSelected, index === 0, index === selected.length - 1)}
                    </div>
                  );
                } else if (value.name == SNS_MENUES[4]) {
                  return (
                    <div className="my-[16px]" key={value.sortId}>
                      {Events(selected, index, setSelected, index === 0, index === selected.length - 1)}
                    </div>
                  );
                } else if (value.name == SNS_MENUES[5]) {
                  return (
                    <div className="my-[16px]" key={value.sortId}>
                      {WebService(selected, index, setSelected, index === 0, index === selected.length - 1)}
                    </div>
                  );
                } else if (value.name == SNS_MENUES[10]) {
                  return (
                    <div className="my-[16px]" key={value.sortId}>
                      {Feed(
                        selected,
                        index,
                        setSelected,
                        index === 0,
                        index === selected.length - 1,
                        advertisementOption
                      )}
                    </div>
                  );
                } else if (value.name == SNS_MENUES[11]) {
                  return (
                    <div className="my-[16px]" key={value.sortId}>
                      {Stories(
                        selected,
                        index,
                        setSelected,
                        index === 0,
                        index === selected.length - 1,
                        advertisementOption
                      )}
                    </div>
                  );
                } else if (value.name == SNS_MENUES[12]) {
                  return (
                    <div className="my-[16px]" key={value.sortId}>
                      {Reel(
                        selected,
                        index,
                        setSelected,
                        index === 0,
                        index === selected.length - 1,
                        advertisementOption
                      )}
                    </div>
                  );
                } else if (value.name == SNS_MENUES[20]) {
                  return (
                    <div className="my-[16px]" key={value.sortId}>
                      {Post(
                        selected,
                        index,
                        setSelected,
                        index === 0,
                        index === selected.length - 1,
                        advertisementOption
                      )}
                    </div>
                  );
                } else if (value.name == SNS_MENUES[21]) {
                  return (
                    <div className="my-[16px]" key={value.sortId}>
                      {RePost(
                        selected,
                        index,
                        setSelected,
                        index === 0,
                        index === selected.length - 1,
                        advertisementOption
                      )}
                    </div>
                  );
                } else if (value.name == SNS_MENUES[30]) {
                  return (
                    <div className="my-[16px]" key={value.sortId}>
                      {TikTok(
                        selected,
                        index,
                        setSelected,
                        index === 0,
                        index === selected.length - 1,
                        advertisementOption
                      )}
                    </div>
                  );
                } else if (value.name == SNS_MENUES[40]) {
                  return (
                    <div className="my-[16px]" key={value.sortId}>
                      {Youtube(
                        selected,
                        index,
                        setSelected,
                        index === 0,
                        index === selected.length - 1,
                        advertisementOption
                      )}
                    </div>
                  );
                }
              })}
            </SortableContext>
          </DndContext>
        </div>
      )}
    </>
  );
}
