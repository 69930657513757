import { SnsType } from '@/api/project';

export const SNS_MENUES: { [key: number]: string } = {
  0: '選択してください',
  1: 'サンプリング',
  2: 'バイイング(店舗)',
  3: 'バイイング(EC)',
  4: 'イベント',
  5: 'WEBサービス・アプリ利用',
  10: 'フィード投稿', // Instagram
  11: 'ストーリーズ投稿', // Instagram
  12: 'リール投稿', // Instagram
  20: 'ポスト', // X
  21: '引用ポスト', // X
  30: '動画投稿　', // Tiktok Youtubeと名称がかぶっており、全く同名だと処理に影響があるため、全角スペースを仕込む。レイアウト、処理に影響はなし
  40: '動画投稿', // Youtube
} as const;

export type itemDetails = {
  itemTitle: string; // 商品名
  itemTitleError: string | undefined; // 商品名エラー
  options: string[]; // 選択肢を追加する
  optionsError: string[] | undefined; // 選択肢エラー
  noAddedOptionError: string | undefined; // 選択肢が1つも存在しないエラー
  allowInfluencerProductPickup: string; // インフルエンサーの商品選択可否 0: 選択可能 1: 選択不可
  maxSelectionCount: number; // 選択可能数
};

export type selectItem = {
  selectExplanation: string; // 選択肢説明
  selectExplanationError: string | undefined; // 選択肢説明エラー
  selectType: string[]; // 選択肢の種類
  selectTypeError: string[] | undefined; // 選択肢エラー
  noAddedSelectTypeError: string | undefined; // 選択肢が1つも存在しないエラー
  maxSelectionCount: number; // 選択可能数
};

export type SelectType = {
  id: number;
  key: SnsType;
  name: string;
  title: string; // 稼働内容タイトル
  titleError: string | undefined; // 稼働内容タイトルエラー
  productInfo: string; // 商品概要
  productInfoError: string | undefined; // 商品概要エラー
  scheduledDeliveryDate: Date | undefined; // サンプリング商品の運営事務局への到着予定日
  scheduledDeliveryDateError: string | undefined; // サンプリング商品の運営事務局への到着予定日エラー
  postPeriodBegin: Date | undefined; // 投稿期間（開始）
  postPeriodBeginError: string | undefined; // 投稿期間（開始）エラー
  postPeriodEnd: Date | undefined; // 投稿期間（終了）
  postPeriodEndError: string | undefined; // 投稿期間（終了）エラー
  postMethod: string; // 投稿方法 1: テキストのみ 2: 画像・動画
  postImageCount: number; // 投稿画像数
  postMovieCount: number; // 投稿動画数
  postMovieAndImageCountError: string | undefined; // 投稿画像数と動画数エラー
  postRequestContent: string; // 投稿依頼内容
  postRequestContentError: string | undefined; // 投稿依頼内容エラー
  advertisement: string; // 投稿に含める広告表示
  hashTag: string; // ハッシュタグ
  hashTagsError: string | undefined; // ハッシュタグエラー
  optionalHashTag: string; // 任意ハッシュタグ
  optionalHashTagError: string | undefined; // 任意ハッシュタグエラー
  hashTags: string[]; // ハッシュタグ
  hashTagError: string[] | undefined; // ハッシュタグエラー
  optionalHashTags: string[]; // 任意ハッシュタグ
  optionalHashTagsError: string[] | undefined; // 任意ハッシュタグエラー
  showToHireeOnlyHashTags: boolean; // ハッシュタグを依頼者のみに公開する 0: 表示する 1: 表示しない
  accountTag: string; // アカウントタグ
  accountTagError: string | undefined; // アカウントタグエラー
  accountTags: string[]; // アカウントタグ
  accountTagsError: string[] | undefined; // アカウントタグエラー
  showToHireeOnlyAccountTags: boolean; // アカウントタグを依頼者のみに公開する 0: 表示する 1: 表示しない
  locationInfo: string; // 場所情報
  lacationInfoError: string | undefined; // 場所情報エラー
  showToHireeOnlyLocationInfo: boolean; // 場所情報を依頼者のみに公開する 0: 表示する 1: 表示しない
  postUrl: string; // 投稿に含めるURL
  postUrlError: string | undefined; // 投稿に含めるURLエラー
  showToHireeOnlyPostUrl: boolean; // URLを依頼者のみに公開する 0: 表示する 1: 表示しない
  movieType: string; // 動画の種類 0: 動画 1: ショート動画
  buyingPeriodStart: Date | undefined; // 購入期間（開始）
  buyingPeriodStartError: string | undefined; // 購入期間エラー
  buyingPeriodEnd: Date | undefined; // 購入期間（終了）
  buyingPeriodEndError: string | undefined; // 購入期間（終了）エラー
  storeInfo: string; // 店舗情報
  storeInfoError: string | undefined; // 店舗情報エラー
  purchaseEvidenceSettings: string; // 購入エビデンス設定 0: 設定しない 1: 設定する
  purchaseEvidenceText: string; // 購入エビデンステキスト
  purchaseEvidenceTextError: string | undefined; // 購入エビデンステキストエラー
  purchaseItemExplanation: string; // 買ってもらう商品説明
  purchaseItemExplanationError: string | undefined; // 買ってもらう商品説明エラー
  purchaseItemPrice: string; // 買ってもらう商品価格
  purchaseItemPriceError: string | undefined; // 買ってもらう商品価格エラー
  eventDatesStart: Date | undefined; // 開催期間（開始）
  eventDatesStartError: string | undefined; // 開催期間（開始）エラー
  eventDatesEnd: Date | undefined; // 開催期間（終了）
  eventDatesEndError: string | undefined; // 開催期間（終了）エラー
  eventDetails: string; // イベント説明
  eventDetailsError: string | undefined; // イベント説明エラー
  shoppingUrl: string; // 購入先URL
  shoppingUrlError: string | undefined; // 購入先URLエラー
  downloadUsagePeriodStart: Date | undefined; // ダウンロー・ド利用期間（開始）
  downloadUsagePeriodStartError: string | undefined; // ダウンロード・利用期間（開始）エラー
  downloadUsagePeriodEnd: Date | undefined; // ダウンロード・利用期間（終了）
  downloadUsagePeriodEndError: string | undefined; // ダウンロード・利用期間（終了）エラー
  downloadURL: string; // ダウンロード・利用期間
  downloadURLError: string | undefined; // ダウンロード・利用期間エラー
  detail: string; // 詳細
  detailError: string | undefined; // 詳細エラー
  productDetails: itemDetails[]; // 商品詳細
  selectLists: selectItem[]; // 選択肢
  highlight: string; // ハイライト なし: 0 あり: 1
  publicWeek: number; // 公開週数
  isNew?: boolean;
  sortId: string;
};

export type Popup_ProjectDetailArgsType = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  snsMenuOpen: boolean;
  setSnsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selected: SelectType[] | undefined;
  setSelected: React.Dispatch<React.SetStateAction<SelectType[] | undefined>>;
  menuId: number;
  setMenuId: React.Dispatch<React.SetStateAction<number>>;
  sns: string;
};
