import clsx from 'clsx';

import { ProjectSchedule } from '@/api/other';
import { scheduleToString } from '@/utils/format';

import { DescriptionItem } from './DescriptionItem';

type ScheduleType = {
  isEdit: boolean;
  scheduleList: ProjectSchedule[];
};
const scheduleLabelList = ['インフルエンサー募集期間', 'インフルエンサー選定期間', '最終確認期間'];

export const Schedule = (props: ScheduleType) => {
  return (
    <div className="h-[326+40px] w-[726.5px] gap-[16px] rounded-lg bg-white pl-[24px] pt-[40px]">
      <div className="flex h-[28px] w-[678.5px] justify-between">
        <div className="h-[28px] w-[344px] content-center">
          <span className="text-lg font-semibold leading-7 text-gray-700">スケジュール</span>
        </div>
      </div>
      <div className="h-[1px] w-[678.5px] pt-[16px]">
        <div className="border-[1px] border-gray-200"></div>
      </div>
      {scheduleLabelList.map((label) => {
        const schedule = props.scheduleList.find((val) => val.label == label);
        return (
          <DescriptionItem
            key={label}
            title={label}
            value={schedule ? scheduleToString(schedule.from, schedule.to) : '-'}
          />
        );
      })}
      <div className="flex flex-row items-center whitespace-pre pt-[16px]">
        <div className="flex min-h-[20px] w-[233px] flex-col">
          <span className="pb-[16px] text-sm font-semibold leading-5 text-gray-500">フィードバック期間</span>
          <div className="flex flex-col gap-2 pl-[10px]">
            <span className="text-sm font-medium leading-5 text-gray-700">インフルエンサー</span>
            <span className="text-sm font-medium leading-5 text-gray-700">企業</span>
          </div>
        </div>
        <div className="min-h-[20px] w-[445.75px] gap-2 pt-[36px]">
          {props.scheduleList.length ? (
            props.scheduleList
              .filter((value) => value.label.includes('フィードバック期間'))
              .map((value, index) => (
                <p
                  key={value.label}
                  className={clsx(
                    'whitespace-pre-line break-words text-sm leading-5 text-gray-900',
                    index !== 0 && 'pt-[8px]'
                  )}
                >
                  {scheduleToString(value.from, value.to)}
                </p>
              ))
          ) : (
            <>
              <p className="whitespace-pre-line break-words text-sm leading-5 text-gray-900">-</p>
              <p className="whitespace-pre-line break-words pt-[8px] text-sm leading-5 text-gray-900">-</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
